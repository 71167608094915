import React from 'react';
import '../assets/Css/Footer.css';
import facebook from '../assets/Images/Facebook_icon.png';
import linkedin from '../assets/Images/Linkdin_icon.png';
import twitter from '../assets/Images/Twitter_icon.png';
import youtube from '../assets/Images/Youtube_icon.png';
import instagram from '../assets/Images/Instragram_icon.png';


const Footer = () => {
  let date = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <div className="footer-subscribe">
            <input type="email" placeholder="dataworks@gmail.com" className="footer-input" />
            <button className="footer-button">Subscribe</button>
          </div>
          <p className="footer-text">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </p>
        </div>
        <div className="footer-right">
          <div className="footer-links">
            <div className="footer-column">
              <p className='footer-heading-names'>CloudSynQ</p>
              <ul>
                <li><a href="#">About</a></li>
                <li><a href="#">What we Offer</a></li>
                <li><a href="#">Leadership</a></li>
                <li><a href="#">Careers</a></li>
              </ul>
            </div>
            <div className="footer-column">
              <p className='footer-heading-names'>Community</p>
              <ul>
                <li><a href="#">Learners</a></li>
                <li><a href="#">Developers</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Careers</a></li>
                <li><a href="#">Leadership</a></li>
              </ul>
            </div>
            <div className="footer-column">
              <p className='footer-heading-names'>More</p>
              <ul>
                <li><a href="#">Press</a></li>
                <li><a href="#">Investors</a></li>
                <li><a href="#">Leadership</a></li>
                <li><a href="#">Help</a></li>
                <li><a href="#">Contact</a></li>
                <li><a href="#">What we Offer</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-copyright">
          <span style={{ fontSize: '1.3em' }}>© </span> {date} CloudSynQ Inc. All rights reserved.
        </p>
        <div className="footer-social">
          <a href="#"><img src={facebook} alt="Facebook" /></a>
          <a href="#"><img src={linkedin} alt="LinkedIn" /></a>
          <a href="#"><img src={twitter} alt="Twitter" /></a>
          <a href="#"><img src={youtube} alt="YouTube" /></a>
          <a href="#"><img src={instagram} alt="Instagram" /></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;