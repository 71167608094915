import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

function SimpleSkeletonLoader() {
  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }} >
      <Grid container spacing={2} style={{marginTop: '20px'}}>
        {Array.from(new Array(3)).map((_, index) => (
          <Grid item xs={4} key={index}>
            <Skeleton variant="rectangular" width="100%" height={300} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default SimpleSkeletonLoader;
