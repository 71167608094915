import React, { useState, useEffect } from 'react';
import '../assets/Css/HeaderAssessment.css';
import { useNavigate } from 'react-router-dom';

const HeaderAssessment = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(10); // 10 seconds for testing purposes
  const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setShowTimeoutPopup(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const ProgressCircle = ({ progress }) => {
    const radius = 20;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - ((100 - progress) / 100) * circumference;

    return (
      <svg className="assessmentheader-progress-circle" width="50" height="50">
        <circle
          className="assessmentheader-progress-background"
          cx="25"
          cy="25"
          r={radius}
          strokeWidth="4"
        />
        <circle
          className="assessmentheader-progress-value"
          cx="25"
          cy="25"
          r={radius}
          strokeWidth="4"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        <text
          x="50%"
          y="40%"
          textAnchor="middle"
          dy=".3em"
          className="assessmentheader-progress-text"
        >
          {`${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? '0' : ''}${timeLeft % 60}`}
        </text>
        <text
          x="50%"
          y="60%"
          textAnchor="middle"
          dy=".3em"
          className="assessmentheader-progress-text"
          fontSize="2"
        >
          left
        </text>
      </svg>
    );
  };

  const progress = (timeLeft / 10) * 100; // Calculate progress based on time left

  return (
    <div className="assessmentheader-container">
      <div className="assessmentheader-left">
        <h1 className="assessmentheader-title">Assessment : Module 1</h1>
      </div>
      <div className="assessmentheader-right">
        <ProgressCircle progress={progress} />
      </div>
      {showTimeoutPopup && (
        <div className="popup-container">
          <div className="timeout-popup">
            <div className="timeout-heading">
              <p>Time's up!</p>
            </div>
            <div className="timeout-buttons">
              <button
                className='timeout-submit-button'
                onClick={() => {
                  setShowTimeoutPopup(false);
                  // Navigate or perform any other action
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderAssessment;
