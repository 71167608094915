import React from 'react'

const ContentProject = () => {
  return (
    <div>
      ContentProject
    </div>
  )
}

export default ContentProject
