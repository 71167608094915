
// import React, { useState, useEffect, useCallback, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import '../assets/Css/AssessmentPage.css';
// import infocircle from '../assets/Images/info-circle.png';
// import { useDropzone } from 'react-dropzone';

// const questionsData = [
//   {
//     _id: "ObjectId('667920f73cd2a9d2b9e60dd1')",
//     ques_text: "What is the capital of Germany?",
//     ques_type: "multiple-choice-single",
//     options: ["Berlin", "Paris", "Madrid", "Rome"],
//     correct_answer: "Berlin"
//   },
//   {
//     _id: "ObjectId('667920f73cd2a9d2b9e60dd2')",
//     ques_text: "Which of the following is a prime number?",
//     ques_type: "multiple-choice-single",
//     options: ["4", "6", "7", "9"],
//     correct_answer: "7"
//   },
//   {
//     _id: "ObjectId('667920f73cd2a9d2b9e60dd3')",
//     ques_text: "Which of the following are programming languages?",
//     ques_type: "multiple-choice-multiple",
//     options: ["Python", "HTML", "CSS", "Java"],
//     correct_answers: ["Python", "Java"]
//   },
//   {
//     _id: "ObjectId('667920f73cd2a9d2b9e60dd4')",
//     ques_text: "Select the fruits from the list below.",
//     ques_type: "multiple-choice-multiple",
//     options: ["Apple", "Carrot", "Banana", "Spinach"],
//     correct_answers: ["Apple", "Banana"]
//   },
  
//   {
//     _id: "ObjectId('667920f73cd2a9d2b9e60dd7')",
//     ques_text: "What will be the output of the following Python code?\n\n```python\nx = [1, 2, 3, 4]\ny = x\ny.append(5)\nprint(x)\n```",
//     ques_type: "multiple-choice-single",
//     options: [
//       "[1, 2, 3, 4]",
//       "[1, 2, 3, 4, 5]",
//       "[1, 2, 3, 4, 4, 5]",
//       "Error"
//     ],
//     correct_answer: "[1, 2, 3, 4, 5]"
//   },
//   {
//     "_id": "ObjectId('667920f73cd2a9d2b9e60dd8')",
//     "ques_text": "What will be the output of the following Python code?\n\n```python\nx = [1, 2, 3]\ny = x\nx = [4, 5, 6]\nprint(y)\n```",
//     "ques_type": "multiple-choice-single",
//     "options": [
//       "[1, 2, 3]",
//       "[4, 5, 6]",
//       "[]",
//       "Error"
//     ],
//     "correct_answer": "[1, 2, 3]"
//   },
//   {
//     _id: "ObjectId('667920f73cd2a9d2b9e60dd9')",
//     ques_text: "The chemical formula of water is _____",
//     ques_type: "fill-in-the-blank",
//     options: ["H2O", "CO2", "O2", "N2"],
//     correct_answer: "H2O"
//   },
//   {
//     _id: "ObjectId('667920f73cd2a9d2b9e60dd10')",
//     ques_text: "The speed of light is approximately _____ km/s.",
//     ques_type: "fill-in-the-blank",
//     options: ["300,000", "150,000", "450,000", "600,000"],
//     correct_answer: "300,000"
//   },
//   {
//     id: "ObjectId('667920f73cd2a9d2b9e60dd5')",
//     ques_text: "As part of a sustainability initiative, a city is considering various measures to reduce carbon emissions and promote eco-friendly practices. One proposed measure is the introduction of a bike-sharing program to encourage residents to use bicycles for short trips instead of cars. The program aims to provide affordable and convenient access to bicycles at multiple locations throughout the city. To evaluate the potential success of this program, the city conducted a survey among residents. The survey results indicated that a significant percentage of respondents were willing to use the bike-sharing program if it were implemented. Additionally, the survey revealed that many residents are concerned about the availability of bike lanes and safety measures for cyclists. Based on the survey results, the city council is debating whether to allocate funds to improve the existing infrastructure, such as building new bike lanes and enhancing safety features at intersections. The decision to move forward with the bike-sharing program and infrastructure improvements is seen as a critical step in achieving the city's long-term sustainability goals. What is the primary concern of residents regarding the bike-sharing program?",
//     ques_type: "multiple-choice-single",
//     options: [
//         "The cost of using the bike-sharing program",
//         "The availability of bike lanes and safety measures",
//         "The number of bike-sharing locations in the city",
//         "The maintenance of the bicycles provided in the program"
//     ],
//     correct_answer: "The availability of bike lanes and safety measures"
//   },
//   { 
//   id:"ObjectId('667920f73cd2a9d2b9e60dd6')",
//     ques_text: "A university is exploring the implementation of a new digital learning platform to enhance the educational experience for students and faculty. The platform will feature interactive course materials, real-time collaboration tools, and personalized learning pathways. As part of the development process, the university conducted a series of focus groups with students, professors, and IT staff to gather feedback on desired features and potential challenges. The feedback highlighted the need for user-friendly navigation, robust security measures, and reliable technical support. Additionally, participants emphasized the importance of integrating the platform with existing university systems and ensuring accessibility for all users. With these insights, the university's IT department is working to develop a pilot version of the platform to test with a small group of users before a full-scale rollout. What was identified as a key requirement for the new digital learning platform based on the feedback?",
//     ques_type: "multiple-choice-single",
//     options: [
//         "High-resolution graphics and multimedia content",
//         "User-friendly navigation and robust security measures",
//         "A wide range of elective courses",
//         "Free access to external educational resources"
//     ],
//     correct_answer: "User-friendly navigation and robust security measures"
//   },
// ];

// const AssessmentPage = () => {
//   const [questions, setQuestions] = useState([]);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [selectedAnswers, setSelectedAnswers] = useState([]);
//   const [skippedQuestions, setSkippedQuestions] = useState([]);
//   const [showReportPopup, setShowReportPopup] = useState(false);
//   const[fillintheblankValue,setFillintheblankValue]=useState("");
//   const [reportDetails, setReportDetails] = useState({
//     incorrectQuestion: false,
//     unresponsiveAnswer: false,
//     correctAnswerUnavailable: false,
//     explanationNotVisible: false,
//     correctOptionUnavailable: false,
//     ctaNotWorking: false,
//     others: '',
//     files: []
//   });
//   console.log(fillintheblankValue)
//   const navigate = useNavigate();
//   const navContainerRef = useRef(null);

//   useEffect(() => {
//     setQuestions(questionsData);
//     setSelectedAnswers(Array(questionsData.length).fill(null));
//   }, []);

//   const handleNext = () => {
//     if (selectedAnswers[currentQuestionIndex] === null && !skippedQuestions.includes(currentQuestionIndex)) {
//       setSkippedQuestions([...skippedQuestions, currentQuestionIndex]);
//     }
//     if (currentQuestionIndex < questions.length - 1) {
//       setCurrentQuestionIndex(currentQuestionIndex + 1);
//       scrollToCurrentQuestion(currentQuestionIndex + 1);
//     } else {
//       handleComplete();
//     }
//   };

//   const handleComplete = () => {
//     const quizResults = {
//       totalQuestions: questions.length,
//       answers: selectedAnswers
//     };

//     console.log('Storing in localStorage:', quizResults);
//     localStorage.setItem('quizResults', JSON.stringify(quizResults));

//     navigate('/quiz-results');
//   };

//   const handlePrevious = () => {
//     if (currentQuestionIndex > 0) {
//       setCurrentQuestionIndex(currentQuestionIndex - 1);
//       scrollToCurrentQuestion(currentQuestionIndex - 1);
//     }
//   };

//   const handleAnswerSelect = (index, type,option) => {
//     if(type === 'fill-in-the-blank'){
//       setFillintheblankValue(option);
//     }
//     const newSelectedAnswers = [...selectedAnswers];
//     if (type === 'multiple-choice-single' || type === 'fill-in-the-blank') {
//       newSelectedAnswers[currentQuestionIndex] = questions[currentQuestionIndex].options[index];
//     } else if (type === 'multiple-choice-multiple') {
//       if (!newSelectedAnswers[currentQuestionIndex]) {
//         newSelectedAnswers[currentQuestionIndex] = [];
//       }
//       const selectedOptions = newSelectedAnswers[currentQuestionIndex];
//       if (selectedOptions.includes(questions[currentQuestionIndex].options[index])) {
//         newSelectedAnswers[currentQuestionIndex] = selectedOptions.filter(opt => opt !== questions[currentQuestionIndex].options[index]);
//       } else {
//         newSelectedAnswers[currentQuestionIndex] = [...selectedOptions, questions[currentQuestionIndex].options[index]];
//       }
//     }
//     setSelectedAnswers(newSelectedAnswers);

    
//   };

//   const handleNavigation = (index) => {
//     setCurrentQuestionIndex(index);
//     scrollToCurrentQuestion(index);
//   };

//   const handleReportChange = (event) => {
//     const { name, checked, value } = event.target;
//     setReportDetails(prevState => ({
//       ...prevState,
//       [name]: checked ? checked : value
//     }));
//   };

//   const handleReportSubmit = () => {
//     console.log('Report Details:', reportDetails);
//     setShowReportPopup(false);
//     setReportDetails(prevState => ({
//       ...prevState,
//       files: []
//     }));
//   };

//   const handleClosePopup = () => {
//     setShowReportPopup(false);
//     setReportDetails(prevState => ({
//       ...prevState,
//       files: []
//     }));
//   };

//   const onDrop = useCallback((acceptedFiles) => {
//     setReportDetails(prevState => ({
//       ...prevState,
//       files: acceptedFiles
//     }));
//   }, []);

//   const { getRootProps, getInputProps } = useDropzone({ onDrop });

//   const scrollToCurrentQuestion = (index) => {
//     if (navContainerRef.current) {
//       const button = navContainerRef.current.querySelector(`.assessmentRendering-nav-button:nth-child(${index + 1})`);
//       if (button) {
//         button.scrollIntoView({ behavior: 'smooth', inline: 'center' });
//       }
//     }
//   };

//   if (!questions || questions.length === 0) {
//     return <div>No questions available</div>;
//   }

//   const isQuestionLong = questions[currentQuestionIndex]?.ques_text.length > 50;
//   const currentQuestion = questions[currentQuestionIndex];

//   return (
//     <div className="assessmentRendering-container2">
//       <div className={`${showReportPopup ? 'blur-background' : ''}`}>
//         <div className='assessmentRendering-nav-report-button'>
//           <div className="assessmentRendering-navigation-buttons2" ref={navContainerRef}>
//             {questions.map((_, index) => (
//               <button
//                 key={index}
//                 className={`assessmentRendering-nav-button 
//                   ${currentQuestionIndex === index ? 'active' : ''} 
//                   ${selectedAnswers[index] !== null ? 'answered' : ''}`}
//                 onClick={() => handleNavigation(index)}
//               >
//                 {index + 1}
//               </button>
//             ))}
//           </div>

//           <div className="assessmentRendering-navigation">
//             <button className="assessmentRendering-report-button2" onClick={() => setShowReportPopup(true)}>Report</button>
//           </div>
//         </div>
//         <div className={`assessmentRendering-content-wrapper`}>
//           <div className={`assessmentRendering-question-options ${isQuestionLong ? 'limited' : ''}`}>
//             <div className={`assessmentRendering-text-body2`}>
//               <div className={`assessmentRendering-options-section2`}>
//                 <div className={`assessmentRendering-question-section2`}>
//                   {/* <p className="assessmentRendering-question-start2">{currentQuestion?.ques_text}</p>
//                   {currentQuestion?.ques_type === 'fill-in-the-blank' && selectedAnswers[currentQuestionIndex] && (
//                     <p className="assessmentRendering-question-start2">
//                       {currentQuestion?.ques_text.replace('_____', selectedAnswers[currentQuestionIndex])}
//                     </p>
//                   )}
//                 </div> */}





// <div className={`assessmentRendering-question-section2`}>
//   <p className="assessmentRendering-question-start2">
//     {currentQuestion?.ques_text.split('```').map((text, index) => (
//       index % 2 === 1 ? (
//         <div key={index} className="code-block">{text}</div>
//       ) : (
//         <div key={index}>{text}</div>
       
//       )
//     ))}
//   </p>
// </div>




//                 {currentQuestion?.options && currentQuestion?.options.map((option, index) => (
//                   <div
//                     key={index}
//                     className={`assessmentRendering-option2 ${selectedAnswers[currentQuestionIndex] && selectedAnswers[currentQuestionIndex].includes(option) ? 'selected' : ''}`}
//                     onClick={() => handleAnswerSelect(index, currentQuestion?.ques_type,option)}
//                   >
//                     <input
//                       type={currentQuestion?.ques_type === 'multiple-choice-multiple' ? 'checkbox' : 'radio'}
//                       name="answer"
//                       id={`option${index}`}
//                       checked={selectedAnswers[currentQuestionIndex] && selectedAnswers[currentQuestionIndex].includes(option)}
//                       readOnly
//                     />
//                     <label htmlFor={`option${index}`}>
//                       {option}
//                     </label>
//                   </div>
//                 ))}
//               </div>
//               <div className="assessmentRendering-button-group2">
//                 {currentQuestionIndex > 0 && (
//                   <button className="assessmentRendering-previousbutton2" onClick={handlePrevious}>Previous</button>
//                 )}
//                 {currentQuestionIndex === 0 && (
//                   <button className="assessmentRendering-previousbutton2 assessmentRendering-placeholder" disabled></button>
//                 )}
//                 {currentQuestionIndex < questions.length - 1 ? (
//                   <button
//                     className={`assessmentRendering-nextbutton2`}
//                     onClick={handleNext}
//                   >
//                     Next
//                   </button>
//                 ) : (
//                   <button
//                     className={`assessmentRendering-submit-button2`}
//                     onClick={handleComplete}
//                   >
//                     Submit
//                   </button>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {showReportPopup && (
//         <div className="popup-container">
//           <div className="report-issue-popup">
//             <div className="report-heading">
//               <img src={infocircle} alt="Report Icon" className='report-icon' />
//               <p>Report an issue</p>
//             </div>
//             <div className="report-checkboxes">
//               <p className='report-paragraph'>Which type of issues are you reporting?</p>
//               <label className="checkbox-label">
//                 <input type="checkbox" name="incorrectQuestion" onChange={handleReportChange} />
//                 Question is incorrect
//               </label>
//               <label className="checkbox-label">
//                 <input type="checkbox" name="unresponsiveAnswer" onChange={handleReportChange} />
//                 Answer option is unresponsive
//               </label>
//               <label className="checkbox-label">
//                 <input type="checkbox" name="correctAnswerUnavailable" onChange={handleReportChange} />
//                 Correct answer option unavailable
//               </label>
//               <label className="checkbox-label">
//                 <input type="checkbox" name="explanationNotVisible" onChange={handleReportChange} />
//                 Explanation was not visible
//               </label>
//               <label className="checkbox-label">
//                 <input type="checkbox" name="correctOptionUnavailable" onChange={handleReportChange} />
//                 Correct answer option unavailable
//               </label>
//               <label className="checkbox-label">
//                 <input type="checkbox" name="ctaNotWorking" onChange={handleReportChange} />
//                 CTA are not working
//               </label>
//               <p className="checkbox-label">Others</p>
//               <div className="others-container">
//                 <textarea name="others" onChange={handleReportChange} placeholder="Write your report here..."></textarea>
//                 <div {...getRootProps({ className: 'drop-box' })}>
//                   <input {...getInputProps()} />
//                   {reportDetails.files.length === 0 ? (
//                     <p className='Drag-Drop-Message'>Drop your reports</p>
//                   ) : (
//                     <div className="report-file-names">
//                       {reportDetails.files.map(file => (
//                         <div key={file.path}>{file.path}</div>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//             <div className="report-buttons">
//               <button className='reportcancel-button' onClick={handleClosePopup}>Cancel</button>
//               <button className='reportsubmit-button' onClick={handleReportSubmit}>Submit</button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//     </div>
//   );
// };

// export default AssessmentPage;








import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../assets/Css/AssessmentPage.css';
import infocircle from '../assets/Images/info-circle.png';
import { useDropzone } from 'react-dropzone';
import leftarrow from '../assets/Images/arrow-left.png';
import rightarrow from '../assets/Images/arrow-right.png';
import OutlinedFlagSharpIcon from '@mui/icons-material/OutlinedFlagSharp';
import Tooltip from '@mui/material/Tooltip';
import Exiticon from '../assets/Images/logout.png';
import { Oval } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

const questionsData = [
  {
    _id: "ObjectId('667920f73cd2a9d2b9e60dd1')",
    ques_text: "1. What is the capital of Germany?",
    ques_type: "multiple-choice-single",
    options: ["Berlin", "Paris", "Madrid", "Rome"],
    correct_answer: "Berlin"
  },
  
  {
    _id: "ObjectId('667920f73cd2a9d2b9e60dd2')",
    ques_text: "2. Which of the following is a prime number?",
    ques_type: "multiple-choice-single",
    options: ["4", "6", "7", "9"],
    correct_answer: "7"
  },
  {
    _id: "ObjectId('667920f73cd2a9d2b9e60dd3')",
    ques_text: "3. Which of the following are programming languages?",
    ques_type: "multiple-choice-multiple",
    options: ["Python", "HTML", "CSS", "Java"],
    correct_answers: ["Python", "Java"]
  },
  {
    _id: "ObjectId('667920f73cd2a9d2b9e60dd4')",
    ques_text: "4. Select the fruits from the list below.",
    ques_type: "multiple-choice-multiple",
    options: ["Apple", "Carrot", "Banana", "Spinach"],
    correct_answers: ["Apple", "Banana"]
  },
  {
    _id: "ObjectId('667920f73cd2a9d2b9e60dd7')",
    ques_text: "5. What will be the output of the following Python code?\n\n```\nx = [1, 2, 3, 4]\ny = x\ny.append(5)\nprint(x)\n```",
    ques_type: "multiple-choice-single",
    options: [
      "[1, 2, 3, 4]",
      "[1, 2, 3, 4, 5]",
      "[1, 2, 3, 4, 4, 5]",
      "Error"
    ],
    correct_answer: "[1, 2, 3, 4, 5]"
  },
  {
    _id: "ObjectId('667920f73cd2a9d2b9e60dd8')",
    ques_text: "6 .What will be the output of the following Python code?\n\n```\nx = [1, 2, 3]\ny = x\nx = [4, 5, 6]\nprint(y)\n```",
    ques_type: "multiple-choice-single",
    options: [
      "[1, 2, 3]",
      "[4, 5, 6]",
      "[]",
      "Error"
    ],
    correct_answer: "[1, 2, 3]"
  },
  {
    _id: "ObjectId('667920f73cd2a9d2b9e60dd12')",
    ques_text: "7 .What will be the output of the following Python code?\n\n```\nx = [1, 2, 3]\ny = x\nx = [4, 5, 6]\nprint(y)\n```",
    ques_type: "multiple-choice-single",
    options: [
      "[1, 2, 3]",
      "[4, 5, 6]",
      "[]",
      "Error"
    ],
    correct_answer: "[1, 2, 3]"
  },
  {
    _id: "ObjectId('667920f73cd2a9d2b9e60dd9')",
    ques_text: "8. The chemical formula of water is _____",
    ques_type: "fill-in-the-blank",
    options: ["H2O", "CO2", "O2", "N2"],
    correct_answer: "H2O"
  },
  {
    _id: "ObjectId('667920f73cd2a9d2b9e60dd13')",
    ques_text: "9. The speed of light is approximately _____ km/s.",
    ques_type: "fill-in-the-blank",
    options: ["300,000", "150,000", "450,000", "600,000"],
    correct_answer: "300,000"
  },
  {
    _id: "ObjectId('667920f73cd2a9d2b9e60dd10')",
    ques_text: "10. The speed of light is approximately _____ km/s.",
    ques_type: "fill-in-the-blank",
    options: ["300,000", "150,000", "450,000", "600,000"],
    correct_answer: "300,000"
  },

  {
    id: "ObjectId('667920f73cd2a9d2b9e60dd5')",
    ques_text: "11. As part of a sustainability initiative, a city is considering various measures to reduce carbon emissions and promote eco-friendly practices. One proposed measure is the introduction of a bike-sharing program to encourage residents to use bicycles for short trips instead of cars. The program aims to provide affordable and convenient access to bicycles at multiple locations throughout the city. To evaluate the potential success of this program, the city conducted a survey among residents. The survey results indicated that a significant percentage of respondents were willing to use the bike-sharing program if it were implemented. Additionally, the survey revealed that many residents are concerned about the availability of bike lanes and safety measures for cyclists. Based on the survey results, the city council is debating whether to allocate funds to improve the existing infrastructure, such as building new bike lanes and enhancing safety features at intersections. The decision to move forward with the bike-sharing program and infrastructure improvements is seen as a critical step in achieving the city's long-term sustainability goals. What is the primary concern of residents regarding the bike-sharing program?",
    ques_type: "multiple-choice-single",
    options: [
        "The cost of using the bike-sharing program",
        "The availability of bike lanes and safety measures",
        "The number of bike-sharing locations in the city",
        "The maintenance of the bicycles provided in the program"
    ],
    correct_answer: "The availability of bike lanes and safety measures"
  },
  { 
  id:"ObjectId('667920f73cd2a9d2b9e60dd6')",
    ques_text: "12. A university is exploring the implementation of a new digital learning platform to enhance the educational experience for students and faculty. The platform will feature interactive course materials, real-time collaboration tools, and personalized learning pathways. As part of the development process, the university conducted a series of focus groups with students, professors, and IT staff to gather feedback on desired features and potential challenges. The feedback highlighted the need for user-friendly navigation, robust security measures, and reliable technical support. Additionally, participants emphasized the importance of integrating the platform with existing university systems and ensuring accessibility for all users. With these insights, the university's IT department is working to develop a pilot version of the platform to test with a small group of users before a full-scale rollout. What was identified as a key requirement for the new digital learning platform based on the feedback?",
    ques_type: "multiple-choice-single",
    options: [
        "High-resolution graphics and multimedia content",
        "User-friendly navigation and robust security measures",
        "A wide range of elective courses",
        "Free access to external educational resources"
    ],
    correct_answer: "User-friendly navigation and robust security measures"
  },
  {
  id:"ObjectId('667920f73cd2a9d2b9e60dd11')",
    ques_text: "13. A university is exploring the implementation of a new digital learning platform to enhance the educational experience for students and faculty. The platform will feature interactive course materials, real-time collaboration tools, and personalized learning pathways. As part of the development process, the university conducted a series of focus groups with students, professors, and IT staff to gather feedback on desired features and potential challenges. The feedback highlighted the need for user-friendly navigation, robust security measures, and reliable technical support. Additionally, participants emphasized the importance of integrating the platform with existing university systems and ensuring accessibility for all users. With these insights, the university's IT department is working to develop a pilot version of the platform to test with a small group of users before a full-scale rollout. What was identified as a key requirement for the new digital learning platform based on the feedback?",
    ques_type: "multiple-choice-single",
    options: [
        "High-resolution graphics and multimedia content",
        "User-friendly navigation and robust security measures",
        "A wide range of elective courses",
        "Free access to external educational resources"
    ],
    correct_answer: "User-friendly navigation and robust security measures"
  }

];



const AssessmentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navContainerRef = useRef(null);
  const { id: courseId } = useParams();



  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [skippedQuestions, setSkippedQuestions] = useState([]);
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes (300 seconds)
  const [fillintheblankValue, setFillintheblankValue] = useState("");
  const [showSelectOptionMessage, setShowSelectOptionMessage] = useState(false);
  const [explanationVisible, setExplanationVisible] = useState(false);
  const [explanationStates, setExplanationStates] = useState(new Array(questionsData.length).fill(false));
  const [showUnattemptedPopup, setShowUnattemptedPopup] = useState(false);
  const [unattemptedQuestionNumbers, setUnattemptedQuestionNumbers] = useState([]);
  const [isAttemptingUnattempted, setIsAttemptingUnattempted] = useState(false);
  const [currentUnattemptedIndex, setCurrentUnattemptedIndex] = useState(0);
  const [showExitPopup, setShowExitPopup] = useState(false);
  const [visibleQuestions, setVisibleQuestions] = useState([0, 10]);
  const [quizData, setQuizData] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [unattemptedAnswers, setUnattemptedAnswers] = useState(0);
  const [answerCorrectness, setAnswerCorrectness] = useState(new Array(questionsData.length).fill(null));
  const [showExplanationContent, setShowExplanationContent] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [reportDetails, setReportDetails] = useState({
    incorrectQuestion: false,
    unresponsiveAnswer: false,
    correctAnswerUnavailable: false,
    explanationNotVisible: false,
    correctOptionUnavailable: false,
    ctaNotWorking: false,
    others: '',
    files: []
  });
 

  useEffect(() => {
    setQuestions(questionsData);
    setSelectedAnswers(Array(questionsData.length).fill(null));
  }, []);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setTimeLeft((prevTime) => {
  //       if (prevTime <= 1) {
  //         clearInterval(timer);
  //         setShowTimeoutPopup(true);
  //         return 0;
  //       }
  //       return prevTime - 1;
  //     });
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, []);

  const scrollToActiveQuestion = (index) => {
    const activeButton = document.getElementById(`question-${index}`);
    if (activeButton && navContainerRef.current) {
      const container = navContainerRef.current;
      const buttonOffsetLeft = activeButton.offsetLeft;
      container.scrollTo({
        left: buttonOffsetLeft - container.offsetLeft - 100, // Adjust to center the active question
        behavior: 'smooth',
      });
    }
  };


   // Scroll left function: show the questions before the current visible range
   const scrollLeft = () => {
    const [start, end] = visibleQuestions;
    if (start > 0) {
      const newStart = Math.max(0, start - 10);
      const newEnd = newStart + 10;
      setVisibleQuestions([newStart, newEnd]);
    }
  };

  // Scroll right function: show the questions after the current visible range
  const scrollRight = () => {
    const [start, end] = visibleQuestions;
    if (end < questionsData.length) {
      const newStart = Math.min(questionsData.length - 10, start + 10);
      const newEnd = newStart + 10;
      setVisibleQuestions([newStart, newEnd]);
    }
  };


   // When currentQuestionIndex changes, update visible questions and scroll to the active one
   useEffect(() => {
    updateVisibleQuestions(currentQuestionIndex);
    scrollToActiveQuestion();
  }, [currentQuestionIndex]);

  const handleNavigationClick = (index) => {
    scrollToActiveQuestion(index); // Scroll to the question when clicked
    updateVisibleQuestions(index); // Update visible question range
    handleNavigation(index); // Set active question logic here
  };

  // Function to calculate the visible range of questions
  const updateVisibleQuestions = (index) => {
    let startIndex = index;
    let endIndex = startIndex + 10;

    // Ensure endIndex does not exceed questionsData length
    if (endIndex > questionsData.length) {
      endIndex = questionsData.length;
      startIndex = Math.max(0, endIndex - 10); // Ensure startIndex does not go below 0
    }

    setVisibleQuestions([startIndex, endIndex]);
  };
  

  // When currentQuestionIndex changes, update visible questions and scroll to the active one
  useEffect(() => {
    updateVisibleQuestions(currentQuestionIndex);
    scrollToActiveQuestion();
  }, [currentQuestionIndex]);

 

  const handleNext = () => {
    if (selectedAnswers[currentQuestionIndex] === null && !skippedQuestions.includes(currentQuestionIndex)) {
      setSkippedQuestions([...skippedQuestions, currentQuestionIndex]);
    }
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      scrollToCurrentQuestion(currentQuestionIndex + 1);
    } else {
      handleComplete();
    }
  };

  const handleComplete = () => {
    setShowTimeoutPopup(false); // Hide the timeout popup if it's visible
    const quizResults = {
      totalQuestions: questions.length,
      answers: selectedAnswers
    };

    console.log('Storing in localStorage:', quizResults);
    localStorage.setItem('quizResults', JSON.stringify(quizResults));

    navigate('/quiz-results');
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      scrollToCurrentQuestion(currentQuestionIndex - 1);
    }
  };

  const handleExitClick = () => {
    setShowExitPopup(true);
  };

  const handleReportClick = () => {
    setShowReportPopup(true);
  };

  const handleContinueQuiz = () => {
    setShowExitPopup(false);
    // Logic to continue the quiz
  };

  const handleContinueToResults = () => {
    const quizResults = {
      totalQuestions: questionsData.length,
      correctAnswers,
      incorrectAnswers,
      unattemptedAnswers: unattemptedQuestionNumbers.length,
    };

    console.log('Storing in localStorage:', quizResults);
    localStorage.setItem('quizResults', JSON.stringify(quizResults));

    navigate(`/quiz-results/${courseId}`, { state: { quizResults, questionsData } });
  };
 // Submit the quiz
 const handleSubmit = () => {
  const unattemptedAnswersCount = selectedAnswers.filter(answer => answer === null).length;
  setUnattemptedAnswers(unattemptedAnswersCount);

  const correctCount = selectedAnswers.filter((answer, index) =>
    questionsData[index].correct_answer === answer
  ).length;

  const incorrectCount = questionsData.length - correctCount - unattemptedAnswersCount;

  setCorrectAnswers(correctCount);
  setIncorrectAnswers(incorrectCount);

  const quizResults = {
    totalQuestions: questionsData.length,
    correctAnswers: correctCount,
    incorrectAnswers: incorrectCount,
    unattemptedAnswers: unattemptedAnswersCount,
  };

  console.log('Storing in localStorage:', quizResults);
  localStorage.setItem('quizResults', JSON.stringify(quizResults));

  navigate(`/quiz-results/${courseId}`, { state: { quizResults, questionsData } });
};

  const handleAnswerSelect = (index, type, option) => {
    if (type === 'fill-in-the-blank') {
      setFillintheblankValue(option);
    }
    const newSelectedAnswers = [...selectedAnswers];
    if (type === 'multiple-choice-single' || type === 'fill-in-the-blank') {
      newSelectedAnswers[currentQuestionIndex] = questions[currentQuestionIndex].options[index];
    } else if (type === 'multiple-choice-multiple') {
      if (!newSelectedAnswers[currentQuestionIndex]) {
        newSelectedAnswers[currentQuestionIndex] = [];
      }
      const selectedOptions = newSelectedAnswers[currentQuestionIndex];
      if (selectedOptions.includes(questions[currentQuestionIndex].options[index])) {
        newSelectedAnswers[currentQuestionIndex] = selectedOptions.filter(opt => opt !== questions[currentQuestionIndex].options[index]);
      } else {
        newSelectedAnswers[currentQuestionIndex] = [...selectedOptions, questions[currentQuestionIndex].options[index]];
      }
    }
    setSelectedAnswers(newSelectedAnswers);
  };

  const handleNavigation = (index) => {
    setCurrentQuestionIndex(index);
    scrollToCurrentQuestion(index);
  };

  const handleReportChange = (event) => {
    const { name, checked, value } = event.target;
    setReportDetails(prevState => ({
      ...prevState,
      [name]: checked ? checked : value
    }));
  };

  const handleReportSubmit = () => {
    console.log('Report Details:', reportDetails);
    setShowReportPopup(false);
    setReportDetails(prevState => ({
      ...prevState,
      files: []
    }));
  };

  const handleClosePopup = () => {
    setShowReportPopup(false);
    setReportDetails(prevState => ({
      ...prevState,
      files: []
    }));
  };

  const handleTimeoutSubmit = () => {
    setShowTimeoutPopup(false);
    handleComplete();
  };

  const onDrop = useCallback((acceptedFiles) => {
    setReportDetails(prevState => ({
      ...prevState,
      files: acceptedFiles
    }));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const scrollToCurrentQuestion = (index) => {
    if (navContainerRef.current) {
      const button = navContainerRef.current.querySelector(`.assessmentRendering-nav-button:nth-child(${index + 1})`);
      if (button) {
        button.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
    }
  };

  if (!questions || questions.length === 0) {
    return <div>No questions available</div>;
  }

  const isQuestionLong = questions[currentQuestionIndex]?.ques_text.length > 50;
  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="assessmentRendering-container2">
      <div className={`${showReportPopup || showTimeoutPopup ? 'blur-background' : ''}`}>
      <div className="quizRendering-nav-report-button">
         
         <div className='quizRendering-nav-left-right-arrow'>
   {/* Left Arrows: Show one or two arrows based on whether there's content to scroll on the left */}
   {visibleQuestions[0] > 0 ? (
     <>
       <img src={leftarrow} className="scroll-left-button" onClick={scrollLeft} />
       {visibleQuestions[0] > 10 && (
         <img src={leftarrow} className="scroll-left-button" onClick={scrollLeft} />
       )}
     </>
   ) : null}
 
   {/* Navigation buttons container */}
   <div className="quizRendering-navigation-buttons2" ref={navContainerRef}>
     {/* Display first 10 questions excluding the last question */}
     {questionsData.slice(visibleQuestions[0], Math.min(visibleQuestions[0] + 10, questionsData.length - 1)).map((_, index) => {
       const actualIndex = visibleQuestions[0] + index;
       return (
        //  <button
        //    key={actualIndex}
        //    id={`question-${actualIndex}`}
        //    className={`quizRendering-nav-button
        //      ${currentQuestionIndex === actualIndex ? 'active' : ''}
        //      ${selectedAnswers[actualIndex] !== null
        //        ? answerCorrectness[actualIndex]
        //          ? 'answered correct'
        //          : 'answered incorrect'
        //        : ''}`}
        //    onClick={() => handleNavigationClick(actualIndex)}
        //  >
        //    {actualIndex + 1}
        //  </button>
        <button
        key={actualIndex}
        id={`question-${actualIndex}`}
        className={`assessmentRendering-nav-button ${
          currentQuestionIndex === actualIndex ? 'assessmentRendering-active' : ''
        } ${
          selectedAnswers[actualIndex] !== null
            ? 'assessmentRendering-attempted'
            : 'assessmentRendering-unattempted'
        }`}
        onClick={() => handleNavigationClick(actualIndex)}
      >
        {actualIndex + 1}
      </button>
      
       );
     })}
 
     {/* Show three dots if more than 10 questions */}
     {questionsData.length > 10 && visibleQuestions[0] + 10 < questionsData.length - 1 && (
       <span className="quizRendering-dots">...</span>
     )}
 
     {/* Show the last question number after the dots */}
     {questionsData.length > 10 && (
       <button
         key={questionsData.length - 1}
         id={`question-${questionsData.length - 1}`}
         className={`quizRendering-nav-button
           ${currentQuestionIndex === questionsData.length - 1 ? 'active' : ''}
           ${selectedAnswers[questionsData.length - 1] !== null
             ? answerCorrectness[questionsData.length - 1]
               ? 'answered correct'
               : 'answered incorrect'
             : ''}`}
         onClick={() => handleNavigationClick(questionsData.length - 1)}
       >
         {questionsData.length}
       </button>
     )}
   </div>
 
   {/* Right Arrows: Show one or two arrows based on whether there's content to scroll on the right */}
   {visibleQuestions[1] < questionsData.length ? (
     <>
       <img src={rightarrow} className="scroll-right-button" onClick={scrollRight} />
       {visibleQuestions[1] + 10 < questionsData.length && (
         <img src={rightarrow} className="scroll-right-button" onClick={scrollRight} />
       )}
     </>
   ) : null}
 </div>
 
 
           {/* Report Icon, Exit Button, and Submit Button */}
           <div className="quizRendering-reporticon-Exit-button-submit">
             {/* Exit Button */}
             {currentQuestionIndex < questionsData.length - 1 && (
               <Tooltip title="Exit Quiz" placement="top" arrow>
                 <button className="quizRendering-exit-button2" onClick={handleExitClick}>
                   <img src={Exiticon} alt="Exit Icon" className="quizRendering-exitbutton-icon" />
                 </button>
               </Tooltip>
             )}
   
             {/* Report Icon */}
             <Tooltip title="Report" placement="top" arrow>
               <OutlinedFlagSharpIcon
                 sx={{
                   fontSize: 'small',
                   color: '#4A4A4A',
                   transform: 'scaleY(0.9)',
                 }}
                 className="quizRendering-reportbutton-icon"
                 onClick={handleReportClick}
               />
             </Tooltip>
   
             {/* Submit Button */}
             <button className="quizRendering-submit-button2" onClick={handleSubmit}>
               Submit
             </button>
           </div>
         </div>
       
   
         
        </div>
        <div className={`assessmentRendering-content-wrapper`}>
          <div className={`assessmentRendering-question-options ${isQuestionLong ? 'limited' : ''}`}>
            <div className={`assessmentRendering-text-body2`}>
              <div className={`assessmentRendering-options-section2`}>
                <div className={`assessmentRendering-question-section2`}>
                  <p className="assessmentRendering-question-start2">
                    {currentQuestion?.ques_text.split('```').map((text, index) => (
                      index % 2 === 1 ? (
                        <div key={index} className="code-block">{text}</div>
                      ) : (
                        <div key={index}>{text}</div>
                      )
                    ))}
                  </p>
                </div>
                {currentQuestion?.options && currentQuestion?.options.map((option, index) => (
                  <div
                    key={index}
                    className={`assessmentRendering-option2 ${selectedAnswers[currentQuestionIndex] && selectedAnswers[currentQuestionIndex].includes(option) ? 'selected' : ''}`}
                    onClick={() => handleAnswerSelect(index, currentQuestion?.ques_type, option)}
                  >
                    <input
                      type={currentQuestion?.ques_type === 'multiple-choice-multiple' ? 'checkbox' : 'radio'}
                      name="answer"
                      id={`option${index}`}
                      checked={selectedAnswers[currentQuestionIndex] && selectedAnswers[currentQuestionIndex].includes(option)}
                      readOnly
                    />
                    <label htmlFor={`option${index}`}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>
              <div className="assessmentRendering-button-group2">
                {currentQuestionIndex > 0 && (
                  <button className="assessmentRendering-previousbutton2" onClick={handlePrevious}>Previous</button>
                )}
                {currentQuestionIndex === 0 && (
                  <button className="assessmentRendering-previousbutton2 assessmentRendering-placeholder" disabled></button>
                )}
                {currentQuestionIndex < questions.length - 1 ? (
                  <button
                    className={`assessmentRendering-nextbutton2`}
                    onClick={handleNext}
                  >
                    Next
                  </button>
                ) : ('')}
              </div>
            </div>
          </div>
        </div>
     
    
      {showReportPopup && (
        <div className="popup-container">
          <div className="report-issue-popup">
            <div className="report-heading">
              <img src={infocircle} alt="Report Icon" className='report-icon' />
              <p>Report an issue</p>
            </div>
            <div className="report-checkboxes">
              <p className='report-paragraph'>Which type of issues are you reporting?</p>
              <label className="checkbox-label">
                <input type="checkbox" name="incorrectQuestion" onChange={handleReportChange} />
                Question is incorrect
              </label>
              <label className="checkbox-label">
                <input type="checkbox" name="unresponsiveAnswer" onChange={handleReportChange} />
                Answer option is unresponsive
              </label>
              <label className="checkbox-label">
                <input type="checkbox" name="correctAnswerUnavailable" onChange={handleReportChange} />
                Correct answer option unavailable
              </label>
              <label className="checkbox-label">
                <input type="checkbox" name="explanationNotVisible" onChange={handleReportChange} />
                Explanation was not visible
              </label>
              <label className="checkbox-label">
                <input type="checkbox" name="correctOptionUnavailable" onChange={handleReportChange} />
                Correct answer option unavailable
              </label>
              <label className="checkbox-label">
                <input type="checkbox" name="ctaNotWorking" onChange={handleReportChange} />
                CTA are not working
              </label>
              <p className="checkbox-label">Others</p>
              <div className="others-container">
                <textarea name="others" onChange={handleReportChange} placeholder="Write your report here..."></textarea>
                <div {...getRootProps({ className: 'drop-box' })}>
                  <input {...getInputProps()} />
                  {reportDetails.files.length === 0 ? (
                    <p className='Drag-Drop-Message'>Drop your reports</p>
                  ) : (
                    <div className="report-file-names">
                      {reportDetails.files.map(file => (
                        <div key={file.path}>{file.path}</div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="report-buttons">
              <button className='reportcancel-button' onClick={handleClosePopup}>Cancel</button>
              <button className='reportsubmit-button' onClick={handleReportSubmit}>Submit</button>
            </div>
          </div>
        </div>
      )}
      {showTimeoutPopup && (
        <div className="popup-container">
          <div className="timeout-popup">
            <div className="timeout-heading">
              <p>Time's up!</p>
            </div>
            <div className="timeout-buttons">
              <button className='timeout-submit-button' onClick={handleTimeoutSubmit}>Submit</button>
            </div>
          </div>
        </div>
      )}
       {/* Exit Popup */}
       {showExitPopup && (
        <div className="quizRendering-exit-popup-container">
          <div className="quizRendering-exit-popup">
            <p>Are you sure you want to exit the quiz?</p>
            <div className="quizRendering-exit-buttons">
              <button className="quizRendering-exit-quiz-button" onClick={handleContinueToResults}>Exit Quiz</button>
              <button className="quizRendering-exit-continue-button" onClick={handleContinueQuiz}>Continue Quiz</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentPage;

