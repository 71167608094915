// reducers.js


// Function to safely get data from localStorage
const getFromLocalStorage = (key) => {
  const value = localStorage.getItem(key);
  return value !== null ? value : undefined;  
};


const initialState = {
  moduleId: getFromLocalStorage("LocalModuleId"),
  topicId: getFromLocalStorage("LocalTopicId"),
  courseId: getFromLocalStorage('localStorageCourseId'),
  mediaContentId: getFromLocalStorage("LocalMediaContentId"),
  labConfigId: getFromLocalStorage("LocalLabId"),
  quizConfigId: getFromLocalStorage("LocalQuizId"),
  particularLabId: null,
  courseTitle: getFromLocalStorage('localStorageCourseTitle'),
  moduleTitle: getFromLocalStorage('ModuleName'),
  topicTitle: getFromLocalStorage('TopicName'),
  notesId:getFromLocalStorage('HtmlId'),
  videoId:getFromLocalStorage('VideoId'),
  PptId : getFromLocalStorage('PPT_id'),
};


const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MODULE_ID":
      return {
        ...state,
        moduleId: action.payload,
      };
    case "SET_TOPIC_ID":
      return {
        ...state,
        topicId: action.payload,
      };
    case "SET_COURSE_ID":
      return {
        ...state,
        courseId: action.payload,
      };
    case "SET_MEDIA_CONTENT_ID":
      return {
        ...state,
        mediaContentId: action.payload,
      };
    case "SET_LAB_CONFIG_ID":
      return {
        ...state,
        labConfigId: action.payload,
      };
    case "SET_QUIZ_CONFIG_ID":
      return {
        ...state,
        quizConfigId: action.payload,
      };
    case "SET_PARTICULARLAB_ID":
      return {
        ...state,
        particularLabId: action.payload,
      };
    case "SET_COURSE_TITLE":
      return {
        ...state,
        courseTitle: action.payload,
      };
    case "SET_MODULE_TITLE":
      return {
        ...state,
        moduleTitle: action.payload,
      };
    case "SET_TOPIC_TITLE":
      return {
        ...state,
        topicTitle: action.payload,
      };
   case "SET_NOTES_ID":
      return {
          ...state,
         notesId: action.payload,
    }; 

    case "SET_VIDEO_ID":
      return {
          ...state,
          videoId: action.payload,
      }; 
    
    case "SET_POWERPOINT_ID":
        return {
            ...state,
            PptId: action.payload,
    };  

    default:
      return state;
  }
};

export default rootReducer;
