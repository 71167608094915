import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleContent } from "../actions/contentActions";
import { useNavigate } from "react-router-dom";
import Sidebar2 from "../Sidebar/Sidebar2";
import "../assets/Css/CourseRendering.css";
import right_arrow from "../assets/Images/arrow-right.png";
import down from "../assets/Images/arrow-down.png";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import { adminaxios } from "../axios";
import { useParams} from 'react-router-dom';
import SkeletonLoader from "./SkeletonLoader";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { setTopicId, setMediaContentId,setLabConfigId,setQuizConfigId ,setNotesId,setVideoId,setPowerPointId,setTopicTitle} from "../actions/apisIdsActions"; // Ensure this import
import { borderRadius } from "@mui/system";
import Ppt from './Ppt'
import "./../assets/Css/CourseRendering.css"


const Combine = () => {
  const contentToggle = useSelector((state) => state.content.contentToggle);
  console.log('contentToggle' , contentToggle)
  const sidebarToggle = useSelector((state) => state.sidebar.sideToggle);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [acticeAskCont,setActiceAskCont] = useState(false);
  const [activeTab, setActiveTab] = useState("notes");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editorData, setEditorData] = useState('');
  const [loading, setLoading] = useState(true);
  const [mediaData, setMediaData] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [pptUrl,setPptUrl] = useState('');
  const [notesUrl, setNotesUrl] = useState("");
  const [topicsArrayData, setTopicsArrayData] = useState([]);
  const [updatedNextTopicId, setUpdatedNextTopicId] = useState(null);
  const [showExitPopup, setShowExitPopup] = useState(false);
  const { id: courseId } = useParams();
  const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  console.log(selectedModuleId);
  const selectedTopicId = useSelector((state) => state.dynamicApiId.topicId);
 
 

  const selectedMediaId = useSelector(
    (state) => state.dynamicApiId.mediaContentId
  );

  const storeQuizConfigId = useSelector(
    (state) => state.dynamicApiId.quizConfigId
  );

  const storeLabConfigId = useSelector(
    (state) => state.dynamicApiId.labConfigId
  );

  const storeNotesId = useSelector((state) => state.dynamicApiId.notesId);
  const storeVideoId = useSelector((state)=> state.dynamicApiId. videoId);
  const storePptId = useSelector((state) => state.dynamicApiId.PptId)
  const contentToggleId = useSelector((state) => state.content.contentToggle);
  console.log(storeVideoId);

  console.log('lab',storeLabConfigId)
  console.log('quiz',storeQuizConfigId)

  const handleAskContModal = async (content) => {
    setShowExitPopup(true);
    const data = { "question":content };
   

    try {
      const response = await adminaxios.post(`discussions/${courseId}/`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response);
      setEditorData('')

    } catch (err) {
      console.error('error:', err);
    }
  };

  useEffect(() => {
    setLoading(true); // Set loading state true when fetching new data
    adminaxios
      .get(`/modules/${selectedModuleId}/`)
      .then((response) => {
        setTopicsArrayData(response.data.topics);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [selectedModuleId]);

  useEffect(() =>{
    if(storeVideoId !== null){
      setLoading(true);
      const fetchData = async () => {
        try {
          const response = await adminaxios.get(`/contents/${storeVideoId}/`);
          const data = response.data;
          const videoUrlFromApi = data.versions[0]?.url;
          setVideoUrl(videoUrlFromApi);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error('Error fetching the data:', error);
        }
      };
  
      fetchData();
    }
  },[storeVideoId])

  useEffect(() =>{
    if(storePptId !== null){
      setLoading(true);
      const fetchData = async () => {
        try {
          const response = await adminaxios.get(`/contents/${storePptId}/`);
          const data = response.data;
          const PptUrlFromApi = data.versions[0]?.url;
          setPptUrl(PptUrlFromApi);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error('Error fetching the data:', error);
        }
      };
  
      fetchData();
    }
  },[storePptId])

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  const handleToggleNotes = () => {
    dispatch(toggleContent());
  };



  const topicsIndex = topicsArrayData.map(topic => topic._id);
  const currentTopicIndex = topicsIndex.indexOf(selectedTopicId);


  const handleToQuiz = () => {
    if (storeNotesId === null && storeQuizConfigId === null && storeLabConfigId === null) {
      if (currentTopicIndex < topicsIndex.length - 1) {
        const nextTopic = topicsArrayData[currentTopicIndex + 1];
        const nextTopicId = nextTopic._id;
        const nextTopicTitle = nextTopic.title
        const nextMediaContentId = nextTopic.media_content_ids[0]?._id;
        const nextMediaContent = nextTopic.media_content_ids[0];
        const nextVideoId = nextMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
        const nextNotesId = nextMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
        const nextPptId = nextMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;
        
        dispatch(setNotesId(nextNotesId));
        dispatch(setVideoId(nextVideoId));
        dispatch(setTopicId(nextTopicId));
        dispatch(setMediaContentId(nextMediaContentId));
        dispatch(setPowerPointId(nextPptId));
        dispatch(setTopicTitle(nextTopicTitle));
        console.log("Next Topic ID:", nextTopicId);
        let labId = '', quizId = '';
        nextTopic.question_bank_configs.forEach(config => {
            if (config.type === 'Lab') {
                labId = config._id;
            } else if (config.type === 'Quiz') {
                quizId = config._id;
            }
        });
        console.log('labId',labId)
        console.log('quizId',quizId)
        if (labId) {
            dispatch(setLabConfigId(labId));
        } else {
            dispatch(setLabConfigId(null));
        }

        if (quizId) {
            dispatch(setQuizConfigId(quizId));
        } else {
            dispatch(setQuizConfigId(null));
        }
        navigate(`/combine/${courseId}`)
      }
    } else if (storeNotesId === null && storeQuizConfigId === null) {
      navigate(`/lab-list/${courseId}`);
    }
    else if(storeNotesId === null) {
      navigate(`/quiz/${courseId}`);
    }else{
      navigate(`/notes/${courseId}`)
    }
  };



  const handleContinueCourse = () => {
    setShowExitPopup(false);
  };

  if (loading) {
    return <SkeletonLoader />;
  }
 

 
  return (
    <div style={{ display: "flex", justifyContent: "flex-start", height: "90vh" }}>
      <Sidebar2 courseId={courseId} />
      <div 
        style={{
          marginLeft: "0px",
          height: "85%",
          width: sidebarToggle ? "100%" : "100%",
          position:'relative',
          zIndex:contentToggle ? '-1' : '0',
        }}
      >
          <ReactPlayer
            url={videoUrl}
            style={{ marginTop: "0px", backgroundColor: "#000" }}
            width="100%"
            height="100%"
            controls
            onEnded={() => setIsModalOpen(true)}
          />
          {/* <Ppt pptxUrl='https://lms-dataworks.s3.amazonaws.com/20240721140213_Presentation2.pptx'
           width="100%x" 
           height="450px" 
          /> */}

          {/* <div className="course-render-modal-cont">
            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              contentLabel="Video Ended"
              style={{
                overlay: {
                  backgroundColor: "transparent",
                  width: sidebarToggle ? "100%" : "100%",
                  height: "100%",
                  marginLeft: sidebarToggle ? "24%" : "1%",
                  transition: "all 0.5s ease-in-out 0s",
                  marginTop:'-33px',
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "70%",
                  background: "linear-gradient(145deg, #060655, #0C0C1A)",
                  opacity: "0.6",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  transition: "width 2s ease 0s",
                  justifyContent: "center",
                  color: "white",
                  marginTop: "22px",
                },
              }}
            >
              <h5 className="course-render-moadal-heading">
                Next, let's assess your learning with a quiz. Head over to the quiz section to continue.
              </h5>

              <button
                onClick={handleToQuiz}
                className="course-render-moadal-continue-btn"
              >
                Continue
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="course-render-moadal-skip-btn"
              >
               Close
              </button>
            </Modal>
          </div> */}
      </div>
      {contentToggleId ?  "" : 
       
         <div
                className='askme-cont'
                style={{
                  width:"0%",
                  display:'flex',
                  alignItems:'center',
                  flexDirection:'column',
                  // justifyContent:'space-around',
                  zIndex:'2000',
                }}
                
          >
          
                    <button
                      onClick={handleToggleNotes}
                      className={
                        // contentToggle ? "notes-direction-button" : "notes-normal"
                        'show-ask-me-container'
                      }
                    
                    >
                    Ask ?
                    </button>
                    {/* <button className="next-topic-combine-button-navigate-text"  onClick={handleSkipVideo}>
                      Skip
                    </button>       */}
            </div>

      }
      
      {contentToggle ? 
         <div className="combine-askme-full-matter-container">
            <div className="combine-askme-full-matter-sub-container"
            >
              <div style={{display:'flex',alignItems:'center'}} >
                <button onClick={handleToggleNotes} className="ask_cont_right_arrow_btn"><img src={right_arrow} alt='back' className="ask_cont_right_arrow" /></button>
                <h4 className="combine-askme-heading-cont">Ask Me</h4>
              </div>
             
              <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorData(data);
                  console.log({ event, editor, data });
                }}
                className='custom-editor'
              />
             <button className="ask-me-post-button" onClick={() =>handleAskContModal(editorData)}>Post</button>
            </div>
 
         </div>
       :''
      }

      {showExitPopup && (
        <div className="course-exit-popup-container">
          <div className="course-exit-popup">
            <p>Successfully Your Question Is Posted</p>
            <div className="course-exit-buttons">
              <button className='course-exit-continue-button' onClick={handleContinueCourse}>Exit</button>
            </div>
          </div>
        </div>
      )}
      
    </div>
  );
};

export default Combine;
