import React from 'react';
import { Card, CardHeader, CardBody } from "reactstrap";
import MentorImg from '../../assets/Images/profile_mentor.png';
import studentsImg from '../../assets/Images/profile-2user.png';
import timerImg from '../../assets/Images/stopwatchTimer.png';
import beginerImg from '../../assets/Images/firstline.png';
import course_img from "../../assets/Images/coursedCardImg.png";
 
const CourseCard = ({ course, handleToCourseRender }) => {
  return (
   
    <Card className="course-details-course-card" onClick={() => handleToCourseRender(course._id, course.title)}>
      <CardHeader style={{ padding: '8px' }}>
        <img
          src={course_img } // Ensure there's a fallback image
          alt={course.title}
          style={{
            width: "100%",
            height: "122px",
            objectFit: "cover",
            borderRadius: '10px',
          }}
        />
      </CardHeader>
 
      <CardBody style={{ padding: "2px 11px 7px 11px" }}>
        <h1 className="course-details_grid_veiw_card_header">{course.title}</h1>
        <div className="course-details_metor_difficulty_alignmnet">
          <div className="course-details_mentor-container">
            <img src={MentorImg} alt="Mentor Image" className="course-details_mentor-image" />
            <div className="course-details_mentor-info">
              <p className="course-details_mentor-name">{course.mentor.name}</p>
              <p className="course-details_mentor-title">{course.mentor.role}</p>
            </div>
          </div>
          <div className="course-details_level-container">
            <img src={beginerImg} alt="Level Image" className="course-details_level-image" />
            <div className="course-details_level-info">
              <p className="course-details_level-heading">{course.level}</p>
              <p className="course-details_level-difficulty">Level</p>
            </div>
          </div>
        </div>
        <div className="course-details_metor_difficulty_alignment2">
          <div className="course-details_learners-container">
            <img src={studentsImg} alt="Learner Image" className="course-details_learners-image" />
            <div className="course-details_learners-info">
              <p className="course-details_learners-heading">Learners</p>
              <p className="course-details_learners-count">{course.learners}</p>
            </div>
          </div>
          <div className="course-details_completion-container">
            <img src={timerImg} alt="Timer Icon" className="course-details_completion-image" />
            <div className="course-details_status-row">
              <p className="course-details_completion-heading">{course.status.label}</p>
              <div className="course-details_progress-bar">
                <div
                  className="course-details_progress-fill"
                  style={{ width: '70%' }} // Adjust the progress percentage here
                ></div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
 
export default CourseCard;