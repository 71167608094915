import React, { useState, useEffect } from 'react';
import CourseCard from './CourseDetailsCard'; // Ensure this path is correct
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import '../../assets/Css/CourseDetails.css';
import { useParams } from 'react-router-dom';
import { adminaxios } from '../../axios';
 
const Recommendations = ({ handleToCourseRender }) => {
  const [showMore, setShowMore] = useState(false);
  const [courses, setCourses] = useState([]);
  const { id: courseId } = useParams();
 
  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const response = await adminaxios.get(`/course_details_page/${courseId}/`);
        const data = response.data;
  
        // Validate the structure of the data and map the recommendations
        if (data.recommendations && Array.isArray(data.recommendations)) {
          const formattedCourses = data.recommendations.map(course => ({
            title: course.course_title,
            mentor: { name: course.metadata.created_by, role: "Mentor" },
            level: course.difficulty_level.charAt(0).toUpperCase() + course.difficulty_level.slice(1),
            learners: `${course.enrollers.length}+`,
            status: { label: "Status", value: course.progress > 0 ? "In Progress" : "Not Started" },
            image: course.image_url,
          }));
  
          // Update the state with formatted courses
          setCourses(formattedCourses);
        } else {
          console.error('Recommendations data is not available or is in an invalid format.');
        }
      } catch (error) {
        console.error('Error fetching recommendations:', error);
      }
    };
  
    // Trigger the fetch when `courseId` changes
    fetchRecommendations();
  }, [courseId]);
  
 
  return (
    <div>
       <h4 className="recommendations-heading">Recommended For You</h4>
      {courses.length === 0 ? (
        <p>No recommendations available.</p>
      ) : (
        <>
          <div className="course-recommendation-row">
         
            {courses.slice(0, 4).map((course, index) => (
              <CourseCard key={index} course={course} handleToCourseRender={handleToCourseRender} />
            ))}
          </div>
          {showMore && (
            <div className="course-recommendation-row">
              {courses.slice(4).map((course, index) => (
                <CourseCard key={index + 4} course={course} handleToCourseRender={handleToCourseRender} />
              ))}
            </div>
          )}
          <div className="course-details-see-more-container">
            <Button onClick={() => setShowMore(!showMore)} className="course-details-see-more-button">
              {showMore ? 'less' : 'More'}
              <ExpandMoreIcon className={`expand-icon ${showMore ? 'rotated' : ''}`} />
            </Button>
          </div>
       
        </>
      )}
   
    </div>
  );
};
 
export default Recommendations;