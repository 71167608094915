import React from 'react';

const Ppt = ({ pptxUrl, width, height }) => {
  const googleDocsViewerUrl = `https://docs.google.com/gview?url=${pptxUrl}&embedded=true`;
  return (
    <div>
      <iframe 
        src={googleDocsViewerUrl} 
        width={width} 
        height={height} 
        style={{ border: 'none' }} 
        // title="PowerPoint Presentation"
      />
    </div>
  );
};

export default Ppt;
