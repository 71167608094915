
// import React, { useState, useEffect } from 'react';
// import { adminaxios } from '../../axios';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Button from '@mui/material/Button';
// import folder from '../../assets/Images/folder-2.png';
// import videoplay from '../../assets/Images/videoplay.png';
// import unlock from '../../assets/Images/unlock.png';
// import { useParams } from 'react-router-dom';
 
// const Modules = ({ onModulesLoad }) => {
//   const [modules, setModules] = useState([]);
//   const [expandedPanels, setExpandedPanels] = useState([]);
//   const [showMore, setShowMore] = useState(false);
//   const { id: courseId } = useParams();
 
//   useEffect(() => {
//     const fetchModules = async () => {
//       try {
//         const response = await adminaxios.get(`/course_details_page/${courseId}/`);
//         const data = response.data; // Axios stores the response data in `response.data`
       
//         if (data && data.curriculum) {
//           const transformedModules = data.curriculum.map((module, index) => ({
//             id: index + 1,
//             title: `Module ${index + 1} - ${module.title}`,
//             content: { text: module.title, time: '04:30' },
//             details: module.topics.map(topic => ({
//               text: topic.title,
//               time: '04:30',
//             })),
//           }));
  
//           setModules(transformedModules);
//           if (onModulesLoad) {
//             onModulesLoad(transformedModules);
//           }
//         } else {
//           console.error('API response does not contain expected curriculum data.');
//         }
//       } catch (error) {
//         console.error('Error fetching modules:', error);
//       }
//     };
  
//     fetchModules();
//   }, [courseId, onModulesLoad]);
 
//   const handleAccordionChange = (id) => (event, isExpanded) => {
//     setExpandedPanels((prevExpanded) =>
//       isExpanded ? [...prevExpanded, id] : prevExpanded.filter((panelId) => panelId !== id)
//     );
//   };
 
//   return (
//     <div className='course-details-second'>
//       <div className="course-modules">
//         <div className='course-single-module'>
//           <h4>Course Modules</h4>
//           {modules.slice(0, showMore ? modules.length : 4).map((module) => (
//             <Accordion
//             key={module.id}
//             expanded={expandedPanels.includes(module.id)}
//             onChange={handleAccordionChange(module.id)}
//             className={expandedPanels.includes(module.id) ? 'accordion-expanded' : ''} // Add custom class based on expansion state
//           >
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls={`panel${module.id}-content`}
//               id={`panel${module.id}-header`}
//             >
//               <div className="accordion-summary-content">
//                 <span className="accordion-summary-title">{module.title}</span>
//               </div>
//             </AccordionSummary>
//             <AccordionDetails className="accordion-details">
//             <p className="course-module-divider"></p>
//             <div className="course-module-container">
//             <div className="course-module-content">
//               {/* Image */}
//               <img src={folder} alt="Document Icon" className="course-content-icon" />

//               {/* Text */}
//               <span className="course-content-text">{module.content.text}</span>

//               {/* Video icon and time */}
//               <div className="course-detail-time">
//                 <img src={videoplay} alt="Video Icon" className="course-video-icon" />
//                 <span>{module.content.time}</span>
//               </div>
//             </div>
//           </div>

              

            

//               <div className="course-module-subcontent">
//                 <img src={unlock} alt="Lock Icon" className="course-subcontent-icon" />
//                 <span>Get the materials</span>
//               </div>

//               {module.details.map((detail, index) => (
//                 <div key={index} className="course-module-detail">
//                   <div className="course-detail-text">
//                     {/* Displaying topic names */}
//                     <span>{detail.text}</span>
//                   </div>
//                   <div className="course-detail-time">
//                     {/* Display video icon and time for every topic */}
//                     <img src={videoplay} alt="Video Icon" className="course-video-icon" />
//                     <span>{detail.time}</span> {/* Assuming each topic has a 'time' field */}
//                   </div>
//                 </div>
//               ))}
            
//           </AccordionDetails>

//           </Accordion>
          
//           ))}
//           <Button onClick={() => setShowMore(!showMore)} className="course-see-more-button">
//             {showMore ? 'Show less' : 'See more'}
//             <ExpandMoreIcon className={`expand-icon ${showMore ? 'rotated' : ''}`} />
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// };
 
// export default Modules;





import React, { useState, useEffect } from 'react';
import { adminaxios } from '../../axios';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import folder from '../../assets/Images/folder-2.png';
import videoplay from '../../assets/Images/videoplay.png';
import unlock from '../../assets/Images/unlock.png';
import { useParams } from 'react-router-dom';

const Modules = ({ onModulesLoad }) => {
  const [modules, setModules] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const { id: courseId } = useParams();

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await adminaxios.get(`/course_details_page/${courseId}/`);
        const data = response.data; // Axios stores the response data in `response.data`

        if (data && data.curriculum) {
          const transformedModules = data.curriculum.map((module, index) => ({
            id: index + 1,
            title: `Module ${index + 1} - ${module.title}`,
            content: { text: module.title, time: '04:30' },
            details: module.topics.map(topic => ({
              text: topic.title,
              time: '04:30',
            })),
          }));

          setModules(transformedModules);
          if (onModulesLoad) {
            onModulesLoad(transformedModules);
          }
        } else {
          console.error('API response does not contain expected curriculum data.');
        }
      } catch (error) {
        console.error('Error fetching modules:', error);
      }
    };

    fetchModules();
  }, [courseId, onModulesLoad]);

  const handleAccordionChange = (id) => (event, isExpanded) => {
    setExpandedPanels((prevExpanded) =>
      isExpanded ? [...prevExpanded, id] : prevExpanded.filter((panelId) => panelId !== id)
    );
  };

  return (
    <div className="course-details-second">
      <div className="course-modules">
        <div className="course-single-module">
          <h4>Course Modules</h4>
          {modules.slice(0, showMore ? modules.length : 4).map((module) => (
            <Accordion
              key={module.id}
              expanded={expandedPanels.includes(module.id)}
              onChange={handleAccordionChange(module.id)}
              TransitionProps={{ unmountOnExit: true }} // Ensure smooth mounting/unmounting transitions
              className={expandedPanels.includes(module.id) ? 'accordion-expanded' : ''}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${module.id}-content`}
                id={`panel${module.id}-header`}
              >
                <div className="accordion-summary-content">
                  <span className="accordion-summary-title">{module.title}</span>
                </div>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <p className="course-module-divider"></p>
                <div className="course-module-container">
                  <div className="course-module-content">
                    <img src={folder} alt="Document Icon" className="course-content-icon" />
                    <span className="course-content-text">{module.content.text}</span>
                    <div className="course-detail-time">
                      <img src={videoplay} alt="Video Icon" className="course-video-icon" />
                      <span>{module.content.time}</span>
                    </div>
                  </div>
                </div>
                <div className="course-module-subcontent">
                  <img src={unlock} alt="Lock Icon" className="course-subcontent-icon" />
                  <span>Get the materials</span>
                </div>
                {module.details.map((detail, index) => (
                  <div key={index} className="course-module-detail">
                    <div className="course-detail-text">
                      <span>{detail.text}</span>
                    </div>
                    <div className="course-detail-time">
                      <img src={videoplay} alt="Video Icon" className="course-video-icon" />
                      <span>{detail.time}</span>
                    </div>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
          <Button onClick={() => setShowMore(!showMore)} className="course-see-more-button">
            {showMore ? 'Show less' : 'See more'}
            <ExpandMoreIcon className={`expand-icon ${showMore ? 'rotated' : ''}`} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modules;
