import axios from 'axios';

// Function to get the token from localStorage
const getToken = () => {
  return localStorage.getItem('tokens');
};

// Create axios instance for login
export const loginaxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_LMSAPP_LOGIN,
});


export const logoutaxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_LMSAPP_LOGIN,
  headers: {
    "Authorization": `Bearer ${getToken()}` // Retrieve the token when creating axios instance
  }
});
// Create axios instance for admin
export const adminaxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_LMSAPP,
  headers: {
    "Authorization": `Bearer ${getToken()}` // Retrieve the token when creating axios instance
  }
});

// Add interceptor to update token before requests
adminaxios.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
