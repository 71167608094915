const initialState = {
    sideToggle: true
  };
  
  function sidebarReducer(state = initialState, action) {
    switch (action.type) {
      case 'TOGGLE_SIDEBAR':
        return {
          ...state,
          sideToggle:!state.sideToggle
        };
      default:
        return state;
    }
  }
  
  export default sidebarReducer;
  