

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import '../assets/Css/QuizPage.css';
import infocircle from '../assets/Images/info-circle.png';
import leftarrow from '../assets/Images/arrow-left.png';
import rightarrow from '../assets/Images/arrow-right.png';
import OutlinedFlagSharpIcon from '@mui/icons-material/OutlinedFlagSharp';
import Tooltip from '@mui/material/Tooltip';
import Exiticon from '../assets/Images/logout.png';
import { Oval } from 'react-loader-spinner';
import { adminaxios } from '../axios/index';
import { useParams } from 'react-router-dom';

const QuizPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navContainerRef = useRef(null);
  const { id: courseId } = useParams();

  const quizDataList = location.state?.quizData?.question_bank_ids || [];
  console.log(quizDataList, "quizDataList");

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizData, setQuizData] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState(new Array(quizDataList.length).fill(null));
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [unattemptedAnswers, setUnattemptedAnswers] = useState(0);
  const [answerCorrectness, setAnswerCorrectness] = useState(new Array(quizDataList.length).fill(null));
  const [showExplanationContent, setShowExplanationContent] = useState(false);
  const [error, setError] = useState(null);
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [reportDetails, setReportDetails] = useState({
    incorrectQuestion: false,
    unresponsiveAnswer: false,
    correctAnswerUnavailable: false,
    explanationNotVisible: false,
    correctOptionUnavailable: false,
    ctaNotWorking: false,
    others: '',
    files: []
  });
  const [showSelectOptionMessage, setShowSelectOptionMessage] = useState(false);
  const [explanationVisible, setExplanationVisible] = useState(false);
  const [explanationStates, setExplanationStates] = useState(new Array(quizDataList.length).fill(false));
  const [showUnattemptedPopup, setShowUnattemptedPopup] = useState(false);
  const [unattemptedQuestionNumbers, setUnattemptedQuestionNumbers] = useState([]);
  const [isAttemptingUnattempted, setIsAttemptingUnattempted] = useState(false);
  const [currentUnattemptedIndex, setCurrentUnattemptedIndex] = useState(0);
  const [showExitPopup, setShowExitPopup] = useState(false);
  const [visibleQuestions, setVisibleQuestions] = useState([0, 10]);
  


  const correctMessages = [
    "Fantastic job! You nailed it.",
    "Well done! That’s the right answer.",
    "Impressive! You’re really on top of this.",
    "Great work! Keep it up!",
    "Excellent! You got it right.",
    "You’re doing amazing! Correct answer.",
    "That’s correct! You’re on a roll.",
    "Perfect! You answered correctly.",
    "Spot on! Well answered.",
    "Outstanding! You’ve got it right."
  ];

  const incorrectMessages = [
    "Oops, not quite. Give it another shot.",
    "Close, but not correct. Try again!",
    "Not this time, but don’t give up!",
    "Incorrect, but you’re learning. Keep going!",
    "Almost! Review and try once more.",
    "That’s not it, but you’re doing great!",
    "Don’t worry, you’re getting closer.",
    "Incorrect, but don’t let it stop you.",
    "Not right, but you’re improving!",
    "Keep trying, you’ll get it next time."
  ];

  const fetchQuestion = useCallback(async (questionId) => {
    try {
      const response = await adminaxios.get(`/question_bank/${questionId}/`);
      const questionData = response.data.quiz_content;
      setQuizData(questionData);
      setLoading(false);
    } catch (error) {
      setError('Error fetching question');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (location.state?.reattempt) {
      handleReattempt();
    } else if (quizDataList.length > 0) {
      fetchQuestion(quizDataList[currentQuestionIndex]);
    } else {
      setLoading(false);
      setError('No question IDs available');
    }
  }, [currentQuestionIndex, quizDataList, fetchQuestion, location.state]);


  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 200); // Update progress every 200 milliseconds
    return () => clearInterval(interval);
  }, []);


  // Function to calculate the visible range of questions
  const updateVisibleQuestions = (index) => {
    let startIndex = index;
    let endIndex = startIndex + 10;

    // Ensure endIndex does not exceed quizDataList length
    if (endIndex > quizDataList.length) {
      endIndex = quizDataList.length;
      startIndex = Math.max(0, endIndex - 10); // Ensure startIndex does not go below 0
    }

    setVisibleQuestions([startIndex, endIndex]);
  };

 
  const scrollToActiveQuestion = (index) => {
    const activeButton = document.getElementById(`question-${index}`);
    if (activeButton && navContainerRef.current) {
      const container = navContainerRef.current;
      const buttonOffsetLeft = activeButton.offsetLeft;
      container.scrollTo({
        left: buttonOffsetLeft - container.offsetLeft - 100, // Adjust to center the active question
        behavior: 'smooth',
      });
    }
  };

   // Scroll left function: show the questions before the current visible range
   const scrollLeft = () => {
    const [start, end] = visibleQuestions;
    if (start > 0) {
      const newStart = Math.max(0, start - 5);
      const newEnd = newStart + 10;
      setVisibleQuestions([newStart, newEnd]);
    }
  };

  // Scroll right function: show the questions after the current visible range
  const scrollRight = () => {
    const [start, end] = visibleQuestions;
    if (end < quizDataList.length) {
      const newStart = Math.min(quizDataList.length - 10, start + 5);
      const newEnd = newStart + 10;
      setVisibleQuestions([newStart, newEnd]);
    }
  };
  
  

  // When currentQuestionIndex changes, update visible questions and scroll to the active one
  useEffect(() => {
    updateVisibleQuestions(currentQuestionIndex);
    scrollToActiveQuestion();
  }, [currentQuestionIndex]);

  const handleNavigationClick = (index) => {
    scrollToActiveQuestion(index); // Scroll to the question when clicked
    updateVisibleQuestions(index); // Update visible question range
    handleNavigation(index); // Set active question logic here
  };

  const handleAnswerSelect = (optionText) => {
    if (!selectedAnswer) {
      const isCorrect = optionText === quizData.question_options.find(option => option.is_correct)?.option_text;
      setSelectedAnswer(optionText);
      setSelectedAnswers(prev => {
        const updatedAnswers = [...prev];
        updatedAnswers[currentQuestionIndex] = optionText;
        return updatedAnswers;
      });
      setAnswerCorrectness(prev => {
        const updatedCorrectness = [...prev];
        updatedCorrectness[currentQuestionIndex] = isCorrect;
        return updatedCorrectness;
      });
      setShowExplanationContent(true);
      setShowSelectOptionMessage(false);
      setExplanationVisible(true);
      setExplanationStates(prev => {
        const updatedStates = [...prev];
        updatedStates[currentQuestionIndex] = true;
        return updatedStates;
      });

      if (isCorrect) {
        setCorrectAnswers(prev => prev + 1);
      } else {
        setIncorrectAnswers(prev => prev + 1);
      }
    }
  };


  const handleNext = () => {
    if (isAttemptingUnattempted) {
      const nextUnattemptedIndex = currentUnattemptedIndex + 1;
      if (nextUnattemptedIndex < unattemptedQuestionNumbers.length) {
        setCurrentUnattemptedIndex(nextUnattemptedIndex);
        const nextQuestionIndex = unattemptedQuestionNumbers[nextUnattemptedIndex] - 1;
        setCurrentQuestionIndex(nextQuestionIndex);
        setSelectedAnswer(selectedAnswers[nextQuestionIndex]);
        setShowExplanationContent(true);
        setExplanationVisible(explanationStates[nextQuestionIndex]);
        setShowSelectOptionMessage(false);
        setLoading(true);
      } else {
        handleSubmit();
      }
    } else {
      if (selectedAnswer === null) {
        setUnattemptedAnswers(prev => prev + 1);
      }
      if (currentQuestionIndex < quizDataList.length - 1) {
        const nextQuestionIndex = currentQuestionIndex + 1;
        setCurrentQuestionIndex(nextQuestionIndex);
        setSelectedAnswer(selectedAnswers[nextQuestionIndex]);
        setShowExplanationContent(true);
        setExplanationVisible(explanationStates[nextQuestionIndex]);
        setShowSelectOptionMessage(false);
        setLoading(true);
      } else {
        handleSubmit();
      }
    }
  };



  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      const prevQuestionIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(prevQuestionIndex);
      setSelectedAnswer(selectedAnswers[prevQuestionIndex]);
      setShowExplanationContent(true);
      setExplanationVisible(explanationStates[prevQuestionIndex]);
      setShowSelectOptionMessage(false);
    }
  };

  const handleSubmit = () => {
    const finalUnattemptedAnswers = selectedAnswers.filter(answer => answer === null).length;

    if (finalUnattemptedAnswers > 0) {
      const unattemptedQuestions = selectedAnswers
        .map((answer, index) => (answer === null ? index + 1 : null))
        .filter(index => index !== null);
      setUnattemptedQuestionNumbers(unattemptedQuestions);
      setShowUnattemptedPopup(true);
    } else {
      const quizResults = {
        totalQuestions: quizDataList.length,
        correctAnswers,
        incorrectAnswers,
        unattemptedAnswers: finalUnattemptedAnswers,
      };

      console.log('Storing in localStorage:', quizResults);
      localStorage.setItem('quizResults', JSON.stringify(quizResults));

      navigate(`/quiz-results/${courseId}`, { state: { quizResults, quizDataList } }); // Pass quizDataList to results page
    }
  };

  const handleExitClick = () => {
    setShowExitPopup(true);
  };

  const handleContinueQuiz = () => {
    setShowExitPopup(false);
    // Logic to continue the quiz
  };

  const handleExitQuiz = () => {
    setShowExitPopup(false);
    navigate(`/quiz-results/${courseId}`); // Navigate to the quiz results page
  };

  const handleReattempt = () => {
    setCurrentQuestionIndex(0);
    setSelectedAnswer(null);
    setSelectedAnswers(new Array(quizDataList.length).fill(null));
    setAnswerCorrectness(new Array(quizDataList.length).fill(null));
    setCorrectAnswers(0);
    setIncorrectAnswers(0);
    setUnattemptedAnswers(0);
    setShowExplanationContent(false);
    setExplanationVisible(false);
    setShowSelectOptionMessage(false);
    setExplanationStates(new Array(quizDataList.length).fill(false));
    setLoading(true);
    fetchQuestion(quizDataList[0]);
  };

  const getAppreciationMessage = () => {
    const isCorrect = selectedAnswer === quizData.question_options.find(option => option.is_correct)?.option_text;
    return isCorrect
      ? correctMessages[currentQuestionIndex % correctMessages.length]
      : incorrectMessages[currentQuestionIndex % correctMessages.length];
  };

  const handleReportChange = (event) => {
    const { name, checked, value } = event.target;
    setReportDetails(prevState => ({
      ...prevState,
      [name]: checked ? checked : value
    }));
  };

  const handleReportClick = () => {
    setShowReportPopup(true);
  };

  const handleReportSubmit = () => {
    console.log('Report Details:', reportDetails);
    setShowReportPopup(false);
    setReportDetails(prevState => ({
      ...prevState,
      files: []
    }));
  };

  const handleClosePopup = () => {
    setShowReportPopup(false);
    setReportDetails(prevState => ({
      ...prevState,
      files: []
    }));
  };

  const onDrop = useCallback((acceptedFiles) => {
    setReportDetails(prevState => ({
      ...prevState,
      files: acceptedFiles
    }));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleNavigation = (index) => {
    setCurrentQuestionIndex(index);
    setSelectedAnswer(selectedAnswers[index]);
    setShowExplanationContent(true);
    setExplanationVisible(explanationStates[index]);
    setShowSelectOptionMessage(false);
    setLoading(true);
  };




  const handleAttemptUnattempted = () => {
    setIsAttemptingUnattempted(true);
    setCurrentUnattemptedIndex(0);
    if (unattemptedQuestionNumbers.length > 0) {
      const firstUnattemptedIndex = unattemptedQuestionNumbers[0] - 1;
      setCurrentQuestionIndex(firstUnattemptedIndex);
      setSelectedAnswer(null);
      setShowUnattemptedPopup(false);
      setLoading(true);
    }
  };


  const handleContinueToResults = () => {
    const quizResults = {
      totalQuestions: quizDataList.length,
      correctAnswers,
      incorrectAnswers,
      unattemptedAnswers: unattemptedQuestionNumbers.length,
    };

    console.log('Storing in localStorage:', quizResults);
    localStorage.setItem('quizResults', JSON.stringify(quizResults));

    navigate(`/quiz-results/${courseId}`, { state: { quizResults, quizDataList } });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Oval height={50} width={50} color="#000080" ariaLabel="loading" />
      </div>
    );
  }


  if (error) {
    return <div>{error}</div>;
  }

  if (!quizData) {
    return <div>No question available</div>;
  }

  const isQuestionLong = quizData?.question_text?.length > 50;

  return (
    <div className="quizRendering-container2">
      <div className={`${showReportPopup ? 'blur-background' : ''}`}>
        <div className="quizRendering-nav-report-button">
         
        <div className='quizRendering-nav-left-right-arrow'>
   {/* Left Arrow */}
   {visibleQuestions[0] > 0 && (
    <img src={leftarrow} className="scroll-left-button" onClick={scrollLeft} />
  )}

  {/* Navigation buttons container */}
  <div className="quizRendering-navigation-buttons2" ref={navContainerRef}>
    {/* Display first 10 questions excluding the last question */}
    {quizDataList.slice(visibleQuestions[0], Math.min(visibleQuestions[0] + 10, quizDataList.length - 1)).map((_, index) => {
      const actualIndex = visibleQuestions[0] + index;
      return (
        <button
          key={actualIndex}
          id={`question-${actualIndex}`}
          className={`quizRendering-nav-button
            ${currentQuestionIndex === actualIndex ? 'active' : ''}
            ${selectedAnswers[actualIndex] !== null
              ? answerCorrectness[actualIndex]
                ? 'answered correct'
                : 'answered incorrect'
              : ''}`}
          onClick={() => handleNavigationClick(actualIndex)}
        >
          {actualIndex + 1}
        </button>
      );
    })}

    {/* Show three dots if more than 10 questions */}
    {quizDataList.length > 10 && visibleQuestions[0] + 10 < quizDataList.length - 1 && (
      <span className="quizRendering-dots">...</span>
    )}

    {/* Show the last question number after the dots */}
    {quizDataList.length > 10 && (
      <button
        key={quizDataList.length - 1}
        id={`question-${quizDataList.length - 1}`}
        className={`quizRendering-nav-button
          ${currentQuestionIndex === quizDataList.length - 1 ? 'active' : ''}
          ${selectedAnswers[quizDataList.length - 1] !== null
            ? answerCorrectness[quizDataList.length - 1]
              ? 'answered correct'
              : 'answered incorrect'
            : ''}`}
        onClick={() => handleNavigationClick(quizDataList.length - 1)}
      >
        {quizDataList.length}
      </button>
    )}
  </div>

  {/* Right Arrow */}
  {visibleQuestions[1] < quizDataList.length && (
    <img src={rightarrow} className="scroll-right-button" onClick={scrollRight} />
  )}
</div>


          {/* Report Icon, Exit Button, and Submit Button */}
          <div className="quizRendering-reporticon-Exit-button-submit">
            {/* Exit Button */}
            {currentQuestionIndex < quizDataList.length - 1 && (
              <Tooltip title="Exit Quiz" placement="top" arrow>
                <button className="quizRendering-exit-button2" onClick={handleExitClick}>
                  <img src={Exiticon} alt="Exit Icon" className="quizRendering-exitbutton-icon" />
                </button>
              </Tooltip>
            )}
  
            {/* Report Icon */}
            <Tooltip title="Report" placement="top" arrow>
              <OutlinedFlagSharpIcon
                sx={{
                  fontSize: 'small',
                  color: '#4A4A4A',
                  transform: 'scaleY(0.9)',
                }}
                className="quizRendering-reportbutton-icon"
                onClick={handleReportClick}
              />
            </Tooltip>
  
            {/* Submit Button */}
            <button className="quizRendering-submit-button2" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
  
      {/* Content Wrapper */}
      <div className={`quizRendering-content-wrapper ${selectedAnswer !== null ? 'expanded' : ''}`}>
        <div className={`quizRendering-question-options ${isQuestionLong ? 'limited' : ''} ${explanationVisible ? 'expanded' : ''}`}>
          <div className={`quizRendering-text-body2 ${explanationVisible ? 'reduced-width' : ''}`}>
            <div className={`quizRendering-options-section2 ${explanationVisible ? 'reduced-width' : ''}`}>
              <div className={`quizRendering-question-section2 ${explanationVisible ? 'restricted' : 'full-width'}`}>
              <div className="quizRendering-question-container">
  {/* Render the question number outside of the question text */}
  <span className="quizRendering-question-number">
    {`${currentQuestionIndex + 1}.`}
  </span>

  {/* Render the question text */}
  <p className="quizRendering-question-text">
    {quizData?.question_text}
  </p>
</div>
</div>
              {quizData?.question_options?.map((option, index) => (
                <div
                  key={index}
                  className={`quizRendering-option2
                    ${selectedAnswer === option.option_text ? (option.option_text === quizData?.question_options.find(opt => opt.is_correct)?.option_text ? 'correct2' : 'incorrect2 shake') : ''}`}
                  onClick={() => handleAnswerSelect(option.option_text)}
                >
                  <input
                    type="radio"
                    name={`answer`}
                    id={`option${index}`}
                    checked={selectedAnswer === option.option_text}
                    onChange={() => handleAnswerSelect(option.option_text)}
                  />
                  <label htmlFor={`option${index}`} className={`${selectedAnswer === option.option_text ? 'selected-text2' : ''}`}>
                    {option.option_text}
                  </label>
                </div>
              ))}
            </div>
            <div className="quizRendering-button-group2">
              {currentQuestionIndex > 0 && (
                <button
                  className={`quizRendering-previousbutton2 ${currentQuestionIndex === quizDataList.length - 1 ? 'last-question-previous-button' : ''}`}
                  onClick={handlePrevious}
                  style={currentQuestionIndex === quizDataList.length - 1 ? { marginLeft: '24.5%' } : {}}
                >
                  Previous
                </button>
              )}
              {currentQuestionIndex === 0 && (
                <button className="quizRendering-previousbutton2 quizRendering-placeholder" disabled></button>
              )}
              {currentQuestionIndex < quizDataList.length - 1 ? (
                <button className="quizRendering-nextbutton2" onClick={handleNext}>
                  Next
                </button>
              ):('')}
            </div>
          </div>
        </div>
  
        {/* Explanation Section */}
        {selectedAnswer !== null && (
          <div className={`quizRendering-explanation-wrapper2 ${selectedAnswer !== null ? 'expanded' : ''}`}>
            <div className={`quizRendering-explanation-container2 ${explanationVisible ? '' : 'hidden'}`}>
              <h2 className="quizRendering-explanation-header2">Explanation</h2>
              <p className="quizRendering-appreciation-message">{getAppreciationMessage()}</p>
              <button
                className="quizRendering-explanation-card-button"
                onClick={() => {
                  setExplanationVisible(!explanationVisible);
                  setExplanationStates(prev => {
                    const updatedStates = [...prev];
                    updatedStates[currentQuestionIndex] = !explanationVisible;
                    return updatedStates;
                  });
                }}
              >
                <img className="quizRendering-explanation-button-arrow-img" src={explanationVisible ? rightarrow : leftarrow} />
              </button>
              {explanationVisible && (
                <>
                  <p className="quizRendering-explanation-text2">{quizData?.answer_explanation}</p>
                  <p className="quizRendering-output-text2">So, the correct answer is:</p>
                  <p className="quizRendering-correct-answer2">{quizData?.question_options.find(option => option.is_correct)?.option_text}</p>
                </>
              )}
            </div>
          </div>
        )}
      </div>
  
      {/* Report Popup */}
      {showReportPopup && (
        <div className="quizRendering-popup-container">
          <div className="quizRendering-report-issue-popup">
            <div className="quizRendering-report-heading">
              <img src={infocircle} alt="Report Icon" className="quizRendering-report-icon" />
              <p>Report an issue</p>
            </div>
            <div className="quizRendering-report-checkboxes">
              <p className="quizRendering-report-paragraph">Which type of issues are you reporting?</p>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="incorrectQuestion" onChange={handleReportChange} />
                Question is incorrect
              </label>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="unresponsiveAnswer" onChange={handleReportChange} />
                Answer option is unresponsive
              </label>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="correctAnswerUnavailable" onChange={handleReportChange} />
                Correct answer option unavailable
              </label>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="explanationNotVisible" onChange={handleReportChange} />
                Explanation was not visible
              </label>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="correctOptionUnavailable" onChange={handleReportChange} />
                Correct answer option unavailable
              </label>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="ctaNotWorking" onChange={handleReportChange} />
                CTA are not working
              </label>
              <p className="quizRendering-checkbox-label">Others</p>
              <div className="quizRendering-others-container">
                <textarea name="others" onChange={handleReportChange} placeholder="Write your report here..."></textarea>
                <div {...getRootProps({ className: 'drop-box' })}>
                  <input {...getInputProps()} />
                  {reportDetails.files?.length === 0 ? (
                    <p className="quizRendering-Drag-Drop-Message">Drop your reports</p>
                  ) : (
                    <div className="quizRendering-report-file-names">
                      {reportDetails?.files?.map(file => (
                        <div key={file.path}>{file.path}</div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="quizRendering-report-buttons">
              <button className="quizRendering-reportcancel-button" onClick={handleClosePopup}>Cancel</button>
              <button className="quizRendering-reportsubmit-button" onClick={handleReportSubmit}>Submit</button>
            </div>
          </div>
        </div>
      )}
  
      {/* Unattempted Popup */}
      {showUnattemptedPopup && (
        <div className="quizRendering-popup-container">
          <div className="quizRendering-unattempted-questions-popup">
            <p>You haven't answered the following questions: </p>
            <p style={{ color: "red" }}>{unattemptedQuestionNumbers.join(', ')}</p>
            <p>Would you like to attempt these questions now?</p>
            <div className="quizRendering-unattempted-buttons">
              <button onClick={handleContinueToResults}>Skip and Proceed</button>
              <button onClick={handleAttemptUnattempted}>Yes</button>
            </div>
          </div>
        </div>
      )}
  
      {/* Exit Popup */}
      {showExitPopup && (
        <div className="quizRendering-exit-popup-container">
          <div className="quizRendering-exit-popup">
            <p>Are you sure you want to exit the quiz?</p>
            <div className="quizRendering-exit-buttons">
              <button className="quizRendering-exit-quiz-button" onClick={handleContinueToResults}>Exit Quiz</button>
              <button className="quizRendering-exit-continue-button" onClick={handleContinueQuiz}>Continue Quiz</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
  
export default QuizPage;