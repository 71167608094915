// import React, { useState, useEffect } from 'react';
// import '../assets/Css/ProfileSettings.css';
// import '../Sidebar/settingssidebar';
// import SettingsSidebar from '../Sidebar/settingssidebar';

// function ProfileSettings() {
//   const [profile, setProfile] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     mobile: '',
//   });

//   const [education, setEducation] = useState({
//     status: 'Freshers',
//     higherEducation: 'PG',
//     passedOut: '2024',
//   });

//   const [address, setAddress] = useState({
//     country: 'India',
//     state: 'Telangana',
//     city: 'Hyderabad',
//     pincode: '500001',
//   });

//   useEffect(() => {
//     // Simulate fetching data from an API
//     fetch('/api/user/profile') // Adjust the API endpoint as necessary
//       .then(response => response.json())
//       .then(data => {
//         setProfile(data.profile);
//         setAddress(data.address); // Assuming 'data' includes address info
//       })
//       .catch(error => console.error('Error fetching profile data:', error));
//   }, []);

//   const handleProfileChange = (e) => {
//     const { name, value } = e.target;
//     setProfile(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const handleEducationChange = (e) => {
//     const { name, value } = e.target;
//     setEducation(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const handleAddressChange = (e) => {
//     const { name, value } = e.target;
//     setAddress(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Updated Profile Data:', profile);
//     };

//   return (
//     <div style={{ display: 'flex', width: '100%' }}>
//       <SettingsSidebar />
//         <div className="profile-settings card">
//       <h4>Account</h4>
//       <p>Setup and manage your profile</p>
      
//       <form onSubmit={handleSubmit}>
//         <div className="section full-name">
//           <h2>Full name</h2>
//           <div className="input-group">
//             <div className="input-field">
//               <p>First name</p>
//               <input type="text" name="firstName" placeholder="First name" value={profile.firstName} onChange={handleProfileChange} disabled />
//             </div>
//             <div className="input-field">
//               <p>Last name</p>
//               <input type="text" name="lastName" placeholder="Last name" value={profile.lastName} onChange={handleProfileChange} disabled />
//             </div>
//           </div>
//         </div>
  
//         <div className="section contact-email">
//           <h2>Contact & Email</h2>
//           <div className="input-group">
//             <div className="input-field">
//               <p>Email</p>
//               <input type="email" name="email" placeholder="Email" value={profile.email} onChange={handleProfileChange} disabled />
//             </div>
//             <div className="input-field">
//               <p>Mobile number</p>
//               <input type="tel" name="mobile" placeholder="Mobile number" value={profile.mobile} onChange={handleProfileChange} disabled />
//             </div>
//           </div>
//         </div>

//         <div className="section education">
//           <h2>Education</h2>
//           <p>Add or edit your education details</p>
//           <div className="input-group">
//             <div className="input-field">
//               <p>Status</p>
//               <select name="status" value={education.status} onChange={handleEducationChange}>
//                 <option value="Freshers">Freshers</option>
//                 <option value="Experienced">Experienced</option>
//               </select>
//             </div>
//             <div className="input-field">
//               <p>Higher education</p>
//               <select name="higherEducation" value={education.higherEducation} onChange={handleEducationChange}>
//                 <option value="UG">UG</option>
//                 <option value="PG">PG</option>
//               </select>
//             </div>
//             <div className="input-field">
//               <p>Passed out</p>
//               <select name="passedOut" value={education.passedOut} onChange={handleEducationChange}>
//                 <option value="2023">2023</option>
//                 <option value="2024">2024</option>
//                 <option value="2025">2025</option>
//               </select>
//             </div>
//           </div>
//         </div>

//         <div className="section residential-address">
//           <h2>Residential Address</h2>
//           <p>Add or update your address</p>
//           <div className="input-group">
//             <div className="input-field">
//               <label>Country</label>
//               <select name="country" value={address.country} onChange={handleAddressChange}>
//                 <option value="India">India</option>
//                 {/* Additional country options */}
//               </select>
//             </div>
//             <div className="input-field">
//               <label>State</label>
//               <select name="state" value={address.state} onChange={handleAddressChange}>
//                 <option value="Telangana">Telangana</option>
//                 {/* Additional state options */}
//               </select>
//             </div>
//             <div className="input-field">
//               <label>City</label>
//               <select name="city" value={address.city} onChange={handleAddressChange}>
//                 <option value="Hyderabad">Hyderabad</option>
//                 {/* Additional city options */}
//               </select>
//             </div>
//             <div className="input-field">
//               <label>Pincode</label>
//               <select name="pincode" value={address.pincode} onChange={handleAddressChange}>
//                 <option value="500001">500001</option>
//                 {/* Additional pincode options */}
//               </select>
//             </div>
//           </div>
//         </div>
//       </form>
//     </div>
//     </div>
    
//   );
// }

// export default ProfileSettings;



// import React, { useState, useEffect } from 'react';
// import '../assets/Css/ProfileSettings.css';
// import '../Sidebar/settingssidebar';
// import SettingsSidebar from '../Sidebar/settingssidebar';
// import profilesettingimg from '../assets/Images/profile-setting_image.png'

// function ProfileSettings() {
//   const [profile, setProfile] = useState({
//     firstName: '',
//     lastName: '', 
//     email: '',
//     mobile: '',
//   });

//   const [education, setEducation] = useState({
//     status: 'Freshers',
//     higherEducation: 'PG',
//     passedOut: '2024',
//   });

//   const [address, setAddress] = useState({
//     country: 'India',
//     state: 'Telangana',
//     city: 'Hyderabad',
//     pincode: '500001',
//   });

//   useEffect(() => {
//     // Simulate fetching data from an API
//     fetch('/api/user/profile') // Adjust the API endpoint as necessary
//       .then(response => response.json())
//       .then(data => {
//         setProfile(data.profile);
//         setAddress(data.address); // Assuming 'data' includes address info
//       })
//       .catch(error => console.error('Error fetching profile data:', error));
//   }, []);

//   const handleProfileChange = (e) => {
//     const { name, value } = e.target;
//     setProfile(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const handleEducationChange = (e) => {
//     const { name, value } = e.target;
//     setEducation(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const handleAddressChange = (e) => {
//     const { name, value } = e.target;
//     setAddress(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Updated Profile Data:', profile);
//      };

//   return (
//     <div style={{ display: 'flex', width: '100%' }}>
//       <SettingsSidebar />
//       <div className="profile-settings card">
//       <div className='headersection_container1'>
//         <h4 className='profile-settings_header-heading'>Account</h4>
//         <p className='profile-settings-header-para'>Setup and manage your profile</p>
//         </div>
//         <hr/>
//   <div class="profile-img-container">
//   <img src={profilesettingimg} alt="Profile Image" class="image-proflie-setting"/>
//   <div class="text-content-img">
//     <h2 className='profile-img-heading'>Charan Suraboyina</h2>
//     <p className='profile-img-para'>Learner</p>
//   </div>
// </div>
        
//         <form onSubmit={handleSubmit}>
//           <div className="profile-settings__section profile-settings__full-name">
//             <h2 className="profile-settings__section-heading">Full name</h2>
//             <div className="profile-settings__input-group">
//               <div className="profile-settings__input-field">
//                 <label className="profile-settings__label">First name</label>
//                 <br/>
//                 <input type="text" name="firstName" placeholder="First name" value={profile.firstName} onChange={handleProfileChange}  className="profile-settings__input"/>
//               </div>
//               <div className="profile-settings__input-field">
//                 <label className="profile-settings__label">Last name</label>
//                 <br/>
//                 <input type="text" name="lastName" placeholder="Last name" value={profile.lastName} onChange={handleProfileChange} className="profile-settings__input"/>
//               </div>
//             </div>
//           </div>
    
//           <div className="profile-settings__section profile-settings__contact-email">
//             <h2 className="profile-settings__section-heading">Contact & Email</h2>
//             <div className="profile-settings__input-group">
//               <div className="profile-settings__input-field">
//                 <label className="profile-settings__label">Email</label>
//                 <br/>
//                 <input type="email" name="email" placeholder="Email" value={profile.email} onChange={handleProfileChange} className="profile-settings__input"/>
//               </div>
//               <div className="profile-settings__input-field">
//                 <label className="profile-settings__label">Mobile number</label>
//                 <br/>
//                 <input type="tel" name="mobile" placeholder="Mobile number" value={profile.mobile} onChange={handleProfileChange} className="profile-settings__input"/>
//               </div>
//             </div>
//           </div>
  
//           <div className="profile-settings__section profile-settings__education">
//             <h2 className="profile-settings__section-heading">Education</h2>
//             <p className='profile-setting-para1'>Add or edit your education details</p>
//             <div className="profile-settings__input-group">
//               <div className="profile-settings__input-field">
//                 <label className="profile-settings__label">Status</label>
//                 <br/>
//                 <select name="status" value={education.status} onChange={handleEducationChange} className="profile-settings__select">
//                   <option value="Freshers">Freshers</option>
//                   <option value="Experienced">Experienced</option>
//                 </select>
//               </div>
//               <div className="profile-settings__input-field">
//                 <label className="profile-settings__label">Higher education</label>
//                 <select name="higherEducation" value={education.higherEducation} onChange={handleEducationChange} className="profile-settings__select">
//                   <option value="UG">UG</option>
//                   <option value="PG">PG</option>
//                 </select>
//               </div>
//               <div className="profile-settings__input-field">
//                 <label className="profile-settings__label">Passed out</label>
//                 <select name="passedOut" value={education.passedOut} onChange={handleEducationChange} className="profile-settings__select">
//                   <option value="2023">2023</option>
//                   <option value="2024">2024</option>
//                   <option value="2025">2025</option>
//                 </select>
//               </div>
//             </div>
//           </div>
  
//           <div className="profile-settings__section profile-settings__residential-address">
//             <h2 className="profile-settings__section-heading">Residential Address</h2>
//             <p className='profile-setting-para1'>Add or update your address</p>
//             <div className="profile-settings__input-group">
//               <div className="profile-settings__input-field">
//                 <label className="profile-settings__label">Country</label>
//                 <select name="country" value={address.country} onChange={handleAddressChange} className="profile-settings__select">
//                   <option value="India">India</option>
//                   {/* Additional country options */}
//                 </select>
//               </div>
//               <div className="profile-settings__input-field">
//                 <label className="profile-settings__label">State</label>
//                 <select name="state" value={address.state} onChange={handleAddressChange} className="profile-settings__select">
//                   <option value="Telangana">Telangana</option>
//                   <option value="AP">AP</option>
//                   {/* Additional state options */}
//                 </select>
//               </div>
//               <div className="profile-settings__input-field">
//                 <label className="profile-settings__label">City</label>
//                 <select name="city" value={address.city} onChange={handleAddressChange} className="profile-settings__select">
//                   <option value="Hyderabad">Hyderabad</option>
//                   <option value="vizag" >Vizag</option>
//                   {/* Additional city options */}
//                 </select>
//               </div>
//               <div className="profile-settings__input-field">
//                 <label className="profile-settings__label">Pincode</label>
//                 <select name="pincode" value={address.pincode} onChange={handleAddressChange} className="profile-settings__select">
//                   <option value="500001">500001</option>
//                   <option value="500001">500002</option>
//                   {/* Additional pincode options */}
//                 </select>
//               </div>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default ProfileSettings;




import React, { useState, useEffect } from 'react';
import '../assets/Css/ProfileSettings.css';
import '../Sidebar/settingssidebar';
import SettingsSidebar from '../Sidebar/settingssidebar';
import profilesettingimg from '../assets/Images/profile-setting_image.png';

function ProfileSettings() {
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
  });

  const [education, setEducation] = useState({
    status: 'Freshers',
    higherEducation: 'PG',
    passedOut: '2024',
  });

  const [address, setAddress] = useState({
    country: 'India',
    state: 'Telangana',
    city: 'Hyderabad',
    pincode: '500001',
  });

  const [progress, setProgress] = useState(75); // Example progress value

  useEffect(() => {
    // Simulate fetching data from an API
    fetch('/api/user/profile') // Adjust the API endpoint as necessary
      .then(response => response.json())
      .then(data => {
        setProfile(data.profile);
        setAddress(data.address); // Assuming 'data' includes address info
      })
      .catch(error => console.error('Error fetching profile data:', error));
  }, []);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEducationChange = (e) => {
    const { name, value } = e.target;
    setEducation(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Updated Profile Data:', profile);
  };

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <SettingsSidebar />
      <div className="profile-settings card">
        <div className='headersection_container1'>
          <h4 className='profile-settings_header-heading'>Account</h4>
          <p className='profile-settings-header-para'>Setup and manage your profile</p>
        </div>
        <hr/>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px', position: 'relative' }}>
          <div style={{ position: 'relative', width: '100px', height: '100px' }}>
            <img src={profilesettingimg} alt="Profile Image" style={{ height: '100px', width: '100px', borderRadius: '50%', position: 'relative', zIndex: 1 }} />
            <svg width="100" height="100" style={{ position: 'absolute', top: '0', left: '0', transform: 'rotate(-90deg)', transformOrigin: 'center' }}>
              <circle
                cx="50"
                cy="50"
                r="45"
                strokeWidth="5"
                style={{ fill: 'none', stroke: '#e6e6e6' }}
              />
              <circle
                cx="50"
                cy="50"
                r="45"
                strokeWidth="5"
                style={{ fill: 'none', stroke: '#000080', strokeDasharray: 2 * Math.PI * 45, strokeDashoffset: 2 * Math.PI * 45 * (1 - progress / 100), transition: 'stroke-dashoffset 0.35s' }}
              />
            </svg>
          </div>
          <div style={{ marginLeft: '10px', marginTop: '20px' }}>
            <h2 className='profile-img-heading'>{profile.firstName} {profile.lastName} Charan Teja </h2>
            <p className='profile-img-para'>Learner</p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="profile-settings__section profile-settings__full-name">
            <h2 className="profile-settings__section-heading">Full name</h2>
            <div className="profile-settings__input-group">
              <div className="profile-settings__input-field">
                <label className="profile-settings__label">First name</label>
                <br/>
                <input type="text" name="firstName" placeholder="First name" value={profile.firstName} onChange={handleProfileChange}  className="profile-settings__input"/>
              </div>
              <div className="profile-settings__input-field">
                <label className="profile-settings__label">Last name</label>
                <br/>
                <input type="text" name="lastName" placeholder="Last name" value={profile.lastName} onChange={handleProfileChange} className="profile-settings__input"/>
              </div>
            </div>
          </div>
    
          {/* <div className="profile-settings__section profile-settings__contact-email">
            <h2 className="profile-settings__section-heading">Contact & Email</h2>
            <p className='profile-setting-para1'>Manage your email and mobile</p>
            <div className="profile-settings__input-group">
              <div className="profile-settings__input-field">
                <label className="profile-settings__label">Email</label>
                <br/>
                <input type="email" name="email" placeholder="Email" value={profile.email} onChange={handleProfileChange} className="profile-settings__input"/>
              </div>
              <div className="profile-settings__input-field">
                <label className="profile-settings__label">Mobile number</label>
                <br/>
                <input type="tel" name="mobile" placeholder="Mobile number" value={profile.mobile} onChange={handleProfileChange} className="profile-settings__input"/>
              </div>
            </div>
          </div> */}

<div className="profile-settings__section profile-settings__contact-email">
  <div className="profile-settings__header">
    <div>
      <h2 className="profile-settings__section-heading">Contact & Email</h2>
      <p className='profile-setting-para1'>Manage your email and mobile</p>
    </div>
    <div>
      <button className="profile-settings__add-button">
        <span className="plus-icon">+</span> Add New
      </button>
    </div>
  </div>
  <div className="profile-settings__input-group">
    <div className="profile-settings__input-field">
      <label className="profile-settings__label">Email</label>
      <br/>
      <input type="email" name="email" placeholder="Email" value={profile.email} onChange={handleProfileChange} className="profile-settings__input"/>
    </div>
    <div className="profile-settings__input-field">
      <label className="profile-settings__label">Mobile number</label>
      <br/>
      <input type="tel" name="mobile" placeholder="Mobile number" value={profile.mobile} onChange={handleProfileChange} className="profile-settings__input"/>
    </div>
  </div>
</div>

  
<div className="profile-settings__section profile-settings__education">
  <div className="profile-settings__input-field" style={{ width: '120px' }}>
    <select name="status" value={education.status} onChange={handleEducationChange} className="profile-settings__select">
      <option value="Freshers">Freshers</option>
      <option value="Experienced">Experienced</option>
    </select>
  </div>
  <div className="profile-settings__header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div>
      <h2 className="profile-settings__section-heading">Education</h2>
      <p className='profile-setting-para1'>Add or edit your education details</p>
    </div>
    <div>
      <button className="profile-settings__add-button">
        <span className="plus-icon">+</span> Add Education
      </button>
    </div>
  </div>
  <div className="profile-settings__input-group">
    <div className="profile-settings__input-field">
      <label className="profile-settings__label">Higher education</label>
      <select name="higherEducation" value={education.higherEducation} onChange={handleEducationChange} className="profile-settings__select">
        <option value="UG">UG</option>
        <option value="PG">PG</option>
      </select>
    </div>
    <div className="profile-settings__input-field">
      <label className="profile-settings__label">Passed out</label>
      <select name="passedOut" value={education.passedOut} onChange={handleEducationChange} className="profile-settings__select">
        <option value="2023">2023</option>
        <option value="2024">2024</option>
        <option value="2025">2025</option>
      </select>
    </div>
  </div>
</div>

  
          <div className="profile-settings__section profile-settings__residential-address">
            <h2 className="profile-settings__section-heading">Residential Address</h2>
            <p className='profile-setting-para1'>Add or update your address</p>
            <div className="profile-settings__input-group">
              <div className="profile-settings__input-field">
                <label className="profile-settings__label">Country</label>
                <select name="country" value={address.country} onChange={handleAddressChange} className="profile-settings__select">
                  <option value="India">India</option>
                  {/* Additional country options */}
                </select>
              </div>
              <div className="profile-settings__input-field">
                <label className="profile-settings__label">State</label>
                <select name="state" value={address.state} onChange={handleAddressChange} className="profile-settings__select">
                  <option value="Telangana">Telangana</option>
                  <option value="AP">AP</option>
                  {/* Additional state options */}
                </select>
              </div>
              <div className="profile-settings__input-field">
                <label className="profile-settings__label">City</label>
                <select name="city" value={address.city} onChange={handleAddressChange} className="profile-settings__select">
                  <option value="Hyderabad">Hyderabad</option>
                  <option value="vizag" >Vizag</option>
                  {/* Additional city options */}
                </select>
              </div>
              <div className="profile-settings__input-field">
                <label className="profile-settings__label">Pincode</label>
                <select name="pincode" value={address.pincode} onChange={handleAddressChange} className="profile-settings__select">
                  <option value="500001">500001</option>
                  <option value="500001">500002</option>
                  {/* Additional pincode options */}
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProfileSettings;

