const initialState = {
    basicSideToggle: true
  };
  
  function basicSidebarReducer(state = initialState, action) {
    switch (action.type) {
      case 'TOGGLE_BASIC_SIDEBAR':
        return {
          ...state,
          basicSideToggle:!state.basicSideToggle
        };
      default:
        return state;
    }
  }
  
  
  export default basicSidebarReducer;
   