import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  username: null,
  role: null,
  refreshToken: null,  
  accessToken: null,
};


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.username = action.payload.username;
      state.role = action.payload.role;
      state.refreshToken = action.payload.refresh;
      state.accessToken = action.payload.access;
    },
    clearCredentials: (state) => {
      state.username = null;
      state.role = null;
      state.refreshToken = null;
      state.accessToken = null;
    },
  },
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;


