import React from 'react';
import '../assets/Css/settingssidebar.css';
import accountIcon from '../assets/Images/Account.png';
import paymentsIcon from '../assets/Images/my_learning.png';
import privacyIcon from '../assets/Images/privacy.png';
import notificationsIcon from '../assets/Images/setting_notificaations.png';
import helpIcon from '../assets/Images/setting_help.png';

const SettingsSidebar = () => {
  return (
    <div className="settings-sidebar">
      <div className="sidebar-item back">
        <span className="back-arrow">&larr;</span> Settings
      </div>
      <hr/>
      <div className="sidebar-item">
        <img src={accountIcon} alt="Account" className="icon" />
        <span>Account</span>
      </div>
      <div className="sidebar-item active">
        <img src={paymentsIcon} alt="Payments & Plans" className="icon" />
        <span>Payments & Plans</span>
      </div>
      <div className="sidebar-item">
        <img src={privacyIcon} alt="Privacy & Security" className="icon" />
        <span>Privacy & Security</span>
      </div>
      <div className="sidebar-item">
        <img src={notificationsIcon} alt="Notifications" className="icon" />
        <span>Notifications</span>
      </div>
      <div className="sidebar-item">
        <img src={helpIcon} alt="Help" className="icon" />
        <span>Help</span>
      </div>
    </div>
  );
};

export default SettingsSidebar;
 