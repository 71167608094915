import React, { useState,useCallback } from 'react';
import '../assets/ContentCss/ContentHome.css';
import Sidebar from '../Sidebar/Sidebar';
import HomeIcon from '../assets/Images/Home_Breadcrumb.png';
import FileIcon from '../assets/Images/content_library.png';
import QuizIcon from '../assets/Images/lightbulb.png';
import LabIcon from '../assets/Images/flask.png';
import ProjectIcon from '../assets/Images/project.png';
import { useDropzone } from 'react-dropzone';
import ContentLibrary from './ContentLibrary';
import ContentQuiz from './ContentQuiz';
import ContentLab from './ContentLab';
import ContentProject from './ContentProject';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import fileUploadImg from '../assets/Images/cms_fileUpload_img.png'

const ContentHome = () => {
 //tab switching
  const [files, setFiles] = useState([]);
  const [activeItem, setActiveItem] = useState('Home');
  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  //file drop function
  const FileDrop = () => {
    
    const onDrop = useCallback((acceptedFiles) => {
      const fileDetailsArray = [];
      acceptedFiles.forEach((file) => {
          if (file.type !== 'folder') {
              const reader = new FileReader();
              reader.onload = () => {
                  const fileDetails = {
                      name: file.name,
                      type: file.type,
                      size: file.size,
                      content: reader.result
                  };
                  fileDetailsArray.push(fileDetails);
                  setFiles(prevFiles => [...prevFiles, ...fileDetailsArray]);
              };
              reader.readAsDataURL(file);
          } else {
              // Handle folder type case, if necessary
              console.log('Folder dropped:', file.name);
          }
      });
  }, []);
  
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
  
    return (
      <div className="file-drop-area" {...getRootProps()}>
        <input {...getInputProps()} webkitdirectory="" directory="" />
        <div className="file-drop-content">
          <img src={fileUploadImg} alt='fileUploadImg' className='cms-fileIcon-img' />
          <p>Drop file</p>
          <button className="browse-button">Browse</button>
        </div>
      </div>
    );
}

//combined darg & drop function
const MainDragDropComponent = () => (
  <div className='drop-middle-cont-cms'>
  <DndProvider backend={HTML5Backend}>
    <FileDrop />
  </DndProvider>
  </div>
);


//component switching based on active component
  const renderContent = () => {
    switch (activeItem) {
      case 'Home':
        return <MainDragDropComponent />;
      case 'My Library':
        return <ContentLibrary files={files} />;
      case 'Quiz':
        return <ContentQuiz />;
      case 'Lab':
        return <ContentLab/>;
      case 'Project':
        return <ContentProject />;
      default:
        return <MainDragDropComponent />;
    }
  };



  return (
    <div className='content-home-main-cont'>
      <Sidebar />
      <div className='content-home-cont'>
        <div className='content-section-one'>
          <ul className='content-button-container'>
            {[
              { name: 'Home', icon: HomeIcon },
              { name: 'My Library', icon: FileIcon },
              { name: 'Quiz', icon: QuizIcon },
              { name: 'Lab', icon: LabIcon },
              { name: 'Project', icon: ProjectIcon }
            ].map(item => (
              <li key={item.name}
                className={`content-button-each-container ${activeItem === item.name ? 'active' : ''}`}
                onClick={() => handleItemClick(item.name)}>
                <img className='content-button-img' src={item.icon} alt={`${item.name}Icon`} />
                <h6 className='content-button-text'>{item.name}</h6>
              </li>
            ))}
          </ul>
        </div>
        <div className='content-section-two'>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default ContentHome;
