export const setModuleId = (moduleId) => {
    return {
        type: 'SET_MODULE_ID',
        payload: moduleId
    };
};


export const setTopicId = (topicId) => {
    return {
        type: 'SET_TOPIC_ID',
        payload: topicId
    };
};

export const setCourseId = (courseId) => {
    return {
        type: 'SET_COURSE_ID',
        payload: courseId
    };
};

export const setMediaContentId = (mediaContentId) => {
    return {
        type: 'SET_MEDIA_CONTENT_ID',
        payload: mediaContentId
    };
};

export const setLabConfigId = (labConfigId) => {
    return {
        type: 'SET_LAB_CONFIG_ID',
        payload: labConfigId
    };
};

export const setQuizConfigId = (quizConfigId) => {
    return {
        type: 'SET_QUIZ_CONFIG_ID',
        payload: quizConfigId
    };
};

export const setParticularLabId = (particularLabId) => {
    return {
        type: 'SET_PARTICULARLAB_ID',
        payload: particularLabId
    };
};

export const setCourseTitle = (courseTitle) => {
    return {
        type: 'SET_COURSE_TITLE',
        payload: courseTitle
    };
};

export const setModuleTitle = (moduleTitle) => {
    return {
        type: 'SET_MODULE_TITLE',
        payload: moduleTitle
    };
};

export const setTopicTitle = (topicTitle) => {
    return {
        type: 'SET_TOPIC_TITLE',
        payload: topicTitle
    };
};

export const setNotesId = (notesId) => {
    return {
        type: 'SET_NOTES_ID',
        payload: notesId
    };
};

export const setVideoId = (videoId) => {
    return {
        type: 'SET_VIDEO_ID',
        payload: videoId
    };
};

export const setPowerPointId = (PptId) => {
    return {
        type: 'SET_POWERPOINT_ID',
        payload: PptId
    };
};