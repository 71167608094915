import React, { useState } from 'react';
import profilepic from '../../assets/Images/profile-pic.png';
import rankingpic from '../../assets/Images/ranking.png';
import rankingstarpic from '../../assets/Images/ranking-star.png';
import lines from '../../assets/Images/lines.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import '../../assets/Css/CourseDetails.css';
 
const ReviewCard = () => {
  return (
    <div className="course-testimonials-section">
    {/* <h3 className="testimonials-heading">What Our Students Say</h3> */}
    <div className="course-testimonial-card">  
      <div className="course-testimonial-header">
        <img src={profilepic} alt="Profile" className="course-testimonial-profile-img" />
        <div className="course-testimonial-header-info">
          <div className="course-testimonial-name">P Kiran Kumar</div>
          <div className="course-testimonial-designation">UX Designer</div>
        </div>
        <div className='course-testimonial-lines'>
          <img src={lines} alt="Icon" />
        </div>
      </div>
      <div className="course-testimonial-review-text">
        Replace any element on the template with your choice of animated texts, images, cartoon characters.
      </div>
      <div className="course-testimonial-footer">
        <div className="course-testimonial-stars">
          <img src={rankingpic} alt="Star" className="course-testimonial-star-img" />
          <img src={rankingpic} alt="Star" className="course-testimonial-star-img" />
          <img src={rankingpic} alt="Star" className="course-testimonial-star-img" />
          <img src={rankingstarpic} alt="Star" className="course-testimonial-star-img" />
          <img src={rankingstarpic} alt="Star" className="course-testimonial-star-img" />
        </div>
        <div className="course-testimonial-rating">3.0</div>
        <div className="course-testimonial-review-date">Reviewed on 21-07-2024</div>
      </div>
    </div>
  </div>
 
  );
};
 
const ReviewCardRow = () => {
  const [showMore, setShowMore] = useState(false);
 
  const testimonials = [
    <ReviewCard key={1} />,
    <ReviewCard key={2} />,
    <ReviewCard key={3} />,
    <ReviewCard key={4} />,
    <ReviewCard key={5} />,
    // Add more testimonials as needed
  ];
 
  return (
    <div>
      <h4 className="testimonials-heading">What Our Students Say</h4>
      <div className="course-testimonial-row">
        {testimonials.slice(0, 3)}
      </div>
      {showMore && (
        <div className="course-testimonial-row">
          {testimonials.slice(3)}
        </div>
      )}
      <div className="course-details-see-more-container">
        <Button onClick={() => setShowMore(!showMore)} className="course-details-see-more-button">
          {showMore ? 'less' : 'More'}
          <ExpandMoreIcon className={`expand-icon ${showMore ? 'rotated' : ''}`} />
        </Button>
      </div>
    </div>
  );
};
 
export default ReviewCardRow;