import React, { useState, useRef, useEffect } from "react";
import course_img from "../assets/Images/coursedCardImg.png";
import studentsImg from "../assets/Images/profile-2user.png";
import MentorImg from "../assets/Images/profile_mentor.png";
import timerImg from "../assets/Images/stopwatchTimer.png";
import beginerImg from "../assets/Images/firstline.png";
import { Card, CardHeader, CardBody } from "reactstrap";
import searchIcon from "../assets/Images/search-normalOne.png";
import filter from "../assets/Images/filter-search-one.png";
import order from "../assets/Images/courseListOrderOne.png";
import Sidebar from "../Sidebar/Sidebar";
import "../assets/Css/CourseList.css";
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { Oval } from 'react-loader-spinner';
import gridIcon from '../assets/Images/dashboard.png';
import rowIcon from '../assets/Images/list.svg';
import { adminaxios } from "../axios";
 import CourseSkeletonLoader from "../CourseList/CourseSkeletonLoader";
import { setCourseTitle } from "../actions/apisIdsActions";

import { setCourseId } from "../actions/apisIdsActions";
import LocalStorageCourseDetails from "./LocalStorageCourseDetails";
import Pagination from '@mui/material/Pagination';
import styled from '@emotion/styled';
import { toggleFooterRedux } from "../actions/footerActions";
import Footer from '../Footer/footer';

const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [isReloaded, setIsReloaded] = useState(false)
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  
  const basicSidebarToggle = useSelector(
    (state) => state.basicSidebar.basicSideToggle
  );
 
const [currentPage, setCurrentPage] = useState(1);
const coursesPerPage = 2; // Number of courses you want to show per page

const handlePageChange = (event, value) => {
  setCurrentPage(value);
};
 
  const [activeTab, setActiveTab] = useState("1");
  const [mentorSearch, setMentorSearch] = useState("");
  const [view, setView] = useState("grid");
  const [currentCourseId, setCurrentCourseId] = useState(null);
  console.log('currentCourseId',currentCourseId)
 
  useEffect(() => {
    adminaxios.get('/courses/view/')
      .then(response => {
        console.log(response.data);
        setCourses(response.data);
        setLoading(false);
       
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);
 
 
 
  const handleToCourseRender = (courseId,courseTitle) => {
    localStorage.setItem('localStorageCourseId',courseId);
    localStorage.setItem('localStorageCourseTitle',courseTitle);
    console.log("Teja", courseId)
    dispatch(setCourseId(courseId));
    setCurrentCourseId(courseId);
    dispatch(setCourseTitle(courseTitle));
    setTimeout(() => {
      navigate(`/course-details/${courseId}`);
    }, 500);
  };
 
 

  const CourseCard = ({ course }) => {
    return (
      <Card className="course-list-course-card"  onClick={() => handleToCourseRender(course._id,course.course_title)}>
        <CardHeader style={{ padding: '8px' }}>
          <img
            src={course_img}
            alt="Course"
            style={{
              width: "100%",
              height: "122px",
              objectFit: "cover",
             borderRadius:'10px',
            }}
          />
        </CardHeader>
 
        <CardBody style={{ padding: "2px 11px 7px 11px" }}>
      <h1 className="cousre_grid_veiw_card_header">{course.course_title}</h1>
      <div className="gridveiw_metor_difficulty_alignmnet">
      <div class="cardgrid_mentor-container">
    <img src={MentorImg} alt="Mentor Image" class="cardgrid_mentor-image" />
    <div class="cardgrid_mentor-info">
        <p class="cardgrid_mentor-name">DataWorks</p>
        <p class="cardgrid_mentor-title">Mentor</p>
    </div>
</div>
<div class="cardgrid_level-container">
    <img src={beginerImg} alt="Beginner Image" class="cardgrid_level-image" />
    <div class="cardgrid_level-info">
        <p class="cardgrid_level-heading">{course.difficulty_level}</p>
        <p class="cardgrid_level-difficulty">Level</p>
    </div>
</div>
</div>
<div className="gridveiw_metor_difficulty_alignment2">
<div class="cardgrid_learners-container">
    <img src={studentsImg} alt="Learner Image" class="cardgrid_learners-image" />
    <div class="cardgrid_learners-info">
        <p class="cardgrid_learners-heading">Learners</p>
        <p class="cardgrid_learners-count">80+</p>
    </div>
</div>
<div className="cardgrid_completion-container">
    <img src={timerImg} alt="Timer Icon" className="cardgrid_completion-image" />
    <div className="cardgrid_status-row">
       <p className="cardgrid_completion-heading">Status</p>
        <div className="cardgrid_progress-bar">
            <div
                className="cardgrid_progress-fill"
                style={{ width: '70%' }} // Adjust the progress percentage here
            ></div>
        </div>
    </div>
</div>
</div>
      </CardBody>
      </Card>
    );
  };
 
  const CourseRow = ({ course }) => {
    return (
    <div className="course-list-course-row"
    onClick={() => handleToCourseRender(course._id,course.course_title)} >
  <img src={course_img} alt="Course" className="course-row-img" />
  <div className="course-row-content">
    <h5 className="course-row-title">{course.course_title}</h5>
    <div className="course-row-header">
      <div className="course-row-left">
        <div className="course-row-mentor">
          <img src={MentorImg} alt="Mentor" className="course-row-mentor-img" />
          <div>
            <p className="course-row-mentor-name">DataWorks</p>
            <p className="course-row-mentor-title">Mentor</p>
          </div>
        </div>
        <div className="course-row-level">
          <img src={beginerImg} alt="beginer" className="course-row-level-img" />
          <div >
          <p class="cardgrid_level-heading">{course.difficulty_level}</p>
        <p class="cardgrid_level-difficulty">Level</p>
        </div>
        </div>
        <div className="course-row-status">
              <img src={timerImg} alt="timer" className="course-row-status-img" />
              <p className="course-row-status-text">
                Status<span className="course-row-status-percent">70%</span>
              </p>
              <progress
                value={course.progress === 0 ? 70 : 0}
                max="100"
                className="course-row-progress"
              ></progress>
        </div>
      </div>
    </div> 
  </div>
</div>
 
    );
  };
 
  const toggleView = (newView) => {
    setView(newView);
  };
 
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
 
 
  const filterCourses = (courses) => {
    return courses.filter(
      (course) =>
        (course.metadata && course.metadata.created_by && course.metadata?.created_by?.toLowerCase().includes(mentorSearch?.toLowerCase())) ||
        course.course_title?.toLowerCase().includes(mentorSearch?.toLowerCase())
    );
  };
 
const indexOfLastCourse = currentPage * coursesPerPage;
const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
const currentCourses = filterCourses(courses).slice(indexOfFirstCourse, indexOfLastCourse);
 
 
 
 
  if (loading) {
    return (
      <CourseSkeletonLoader/>
    );
  }
 
  
  return (
    <>
      {currentCourseId && (
        <LocalStorageCourseDetails
          courseId={currentCourseId}
          onCompleted={() => {
            setCurrentCourseId(null);
           
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          height: "90vh",
          width: basicSidebarToggle ? "100%" : "100%",
         
        }}
        
      >
        <Sidebar />
        <div
          id="course-List-main-container"
          style={{
            width: "100%",
            overflowY: "scroll",
            scrollbarWidth: "thin",
            margin: "10px 0px 0px 0px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
              marginTop: "10px",
              marginLeft:'30px',
            }}
          >
            <div className="toggle-btn-course-list-container">
              <button
                className={`toggle-btn-course-list-component ${
                  activeTab === "1" ? "active" : ""
                }`}
                onClick={() => toggle("1")}
              >
                Role based
              </button>
              <button
                className={`toggle-btn-course-list-component ${
                  activeTab === "2" ? "active" : ""
                }`}
                onClick={() => toggle("2")}
              >
                Skill based
              </button>
            </div>
            <div
              style={{
                display: "flex",
                marginRight: "18px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginLeft: "26px",
                  padding: "2px 3px",
                  borderRadius: "90px",
                  backgroundColor: "#F6F6FB",
                  border: "solid 1px #EEEEEE",
                }}
              >
                <button
                  onClick={() => toggleView("grid")}
                  className={`toggle-view-btn ${view === "grid" ? "active" : ""}`}
                >
                  <img src={gridIcon} alt="Grid View" />
                </button>
                <button
                  onClick={() => toggleView("row")}
                  className={`toggle-view-btn ${view === "row" ? "active" : ""}`}
                >
                  <img src={rowIcon} alt="Row View" />
                </button>
              </div>
              <div>
                <img
                  src={filter}
                  alt="icon"
                  style={{
                    height: "21px",
                    width: "21px",
                    marginLeft: "26px",
                    cursor: "pointer",
                    marginTop:'7px',
                  }}
                />
                <img
                  src={order}
                  alt="icon"
                  style={{
                    height: "21px",
                    width: "21px",
                    marginLeft: "26px",
                    cursor: "pointer",
                    marginTop:'7px',
                  }}
                />
              </div>
            </div>
          </div>
          {activeTab === "1" ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                marginBottom: "500px",
                flexDirection: view === "grid" ? "row" : "column",
                marginLeft:'30px',
              }}
            >
              {currentCourses.map((course) =>
                view === "grid" ? (
                  <CourseCard key={course._id} course={course} />
                ) : (
                  <CourseRow key={course._id} course={course} />
                )
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                marginBottom: "50px",
                flexDirection: view === "grid" ? "row" : "column",
              }}
            >
              {currentCourses.map((course) =>
                view === "grid" ? (
                  <CourseCard key={course._id} course={course} />
                ) : (
                  <CourseRow key={course._id} course={course} />
                )
              )}
            </div>
          )}
        
          <Pagination
            count={Math.ceil(filterCourses(courses).length / coursesPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            sx={{
              marginTop: '10px',
              marginBottom:'40px',
              alignSelf:'flex-end',
            }}
          /> 
         <div className="Course-list-footer">
         <Footer />
         </div>
        </div>  
      </div>
    </>
  );
 
};
 
export default CourseList;
