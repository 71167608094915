import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, matchPath } from 'react-router-dom';
import Ppt from './CourseRendering/Ppt';
import Video from './CourseRendering/Video';
import Combine from './CourseRendering/Combine';
import Quiz from './Quiz/Quiz';
import QuizPage from './Quiz/QuizPage';
import QuizResults from './Quiz/QuizResults';
import Header from './Header/Header';
import Header1 from './Header/Header1';
import CodeEditor from './ProblemSolving/CodeEditor';
import CourseList from './CourseList/CourseList';
import Signin from './Authentication/Signin';
import ProfileSettings from './Settings/ProfileSettings';
import PrivacySettings from './Settings/PrivacySettings';
import Signup from './Authentication/Signup';
import VerifyEmail from './Authentication/VerifyEmail';
import OtpConfirm from './Authentication/OtpConfirm';
import ForgotPassword from './Authentication/ForgotPassword';
import ChangePassword from './Authentication/ChangePassword';
import SetNewPassword from './Authentication/SetNewPassword';
import SuccessMessage from './Authentication/SuccessMessage';
import ContentHome from './ContentManagement/ContentHome';
import Assessment from './Assessment/Assessment';
import AssessmentPage from './Assessment/AssessmentPage';
import HeaderAssessment from './Header/HeaderAssessment';
import TimedLab from './ProblemSolving/TimedLab';
import LabLists from './ProblemSolving/LabLists';
import HeaderForQuizPage from './Header/HeaderForQuizPage';
import Notes from './Notes/notes';
import CourseDetails from './CourseList/CourseDetails/CourseDetails';
import Sample_iframe from './ProblemSolving/Sample_iframe';
import Learning from './Learning/Learning';
import DashBoard from './DashBoard/Dashboard';
import Users from './Users/Users';
import Repots from './Reports/Reports';
import Enrollment from './Enrollment/Enrollment' ;
import ModuleDetailsDisplay from './CourseRendering/ModuleDetailsDisplay';


import './App.css';
 
function Layout() {
  const location = useLocation();
 
  let HeaderComponent = Header1; // Default to Header1
 
  // Determine which header to use
  if (
    location.pathname === '/course-list' ||
    location.pathname === '/change-password' ||
    location.pathname === '/profile-settings' ||
    location.pathname === '/privacy-settings' ||
    location.pathname === '/dashboard' ||
    location.pathname === '/users' ||
    location.pathname === '/enrollment' ||
    location.pathname === '/reports' ||
    location.pathname === '/learning' ||
    matchPath('/course-details/:id', location.pathname)
  ) {
    HeaderComponent = Header;
  } else if (
    matchPath('/lab/:id', location.pathname) ||
    location.pathname === '/' ||
    location.pathname === '/signup' ||
    location.pathname === '/verify-email' ||
    location.pathname === '/otp-confirm' ||
    location.pathname === '/set-new-password' ||
    location.pathname === '/success-message' ||
    location.pathname === '/forgot-password' ||
    location.pathname === '/timed-lab' 
  
  ) {
    HeaderComponent = null; // Don't show any header on these pages
  } else if (location.pathname === '/assessment-page') 
    {
    HeaderComponent = HeaderAssessment;
  }
  
 
  return (
    <div>
      {HeaderComponent && <HeaderComponent />}
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/video" element={<Video />} />
        <Route path="/ppt" element={<Ppt />} />
        <Route path="/combine/:id" element={<Combine />} />
        <Route path="/quiz/:id" element={<Quiz />} />
        <Route path="/quiz-page/:id" element={<QuizPage />} />
        <Route path="/quiz-results/:id" element={<QuizResults />} />
        <Route path="/lab/:id" element={<CodeEditor />} />
        <Route path="/course-list" element={<CourseList />} />
        <Route path="/profile-settings" element={<ProfileSettings />} />
        <Route path="/privacy-settings" element={<PrivacySettings />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/otp-confirm" element={<OtpConfirm />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/set-new-password" element={<SetNewPassword />} />
        <Route path="/success-message" element={<SuccessMessage />} />
        <Route path="/content-management" element={<ContentHome />} />
        <Route path="/assessment" element={<Assessment />} />
        <Route path="/assessment-page" element={<AssessmentPage />} />
        <Route path="/timed-lab" element={<TimedLab />} />
        <Route path="/lab-list/:id" element={<LabLists />} />
        <Route path="/course-details/:id" element={<CourseDetails />} />
        <Route path='/notes/:id' element={<Notes />} />
        <Route path='/users' element={<Users />} />
        <Route path='/dashboard' element={<DashBoard />} />
        <Route path='/reports' element={<Repots />} />
        <Route path='/learning' element={<Learning />} />
        <Route path='/enrollment' element={<Enrollment />} />
        <Route path='/module-information/:id' element={<ModuleDetailsDisplay />} />
      </Routes>
    </div>
  );
}
 

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}
 
export default App;
