import React, { useState } from 'react';
import '../assets/Css/Sidebar.css';
import { ChevronLeft, ChevronRight } from 'react-feather';
import dashboardIcon from '../assets/Images/dashboard.png';
import coursesIcon from '../assets/Images/courses.png';
import myLearningIcon from '../assets/Images/my_learning2.png';
import contentLibraryIcon from '../assets/Images/content_library.png';
import userManagementIcon from '../assets/Images/user_management.png';
import enrollmentIcon from '../assets/Images/enrollment.png';
import reportsIcon from '../assets/Images/reports.png';
import { useDispatch, useSelector } from "react-redux";
import { toggleBasicSidebarRedux } from '../actions/basicSidebarAction';
import { useNavigate, useLocation } from 'react-router-dom';
import { Height } from '@mui/icons-material';
import { height } from '@mui/system';

const Sidebar = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const footerHeightAndWidth = useSelector((state) => state.footerAlign.footToggle)
    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
        dispatch(toggleBasicSidebarRedux());
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    const getActiveClass = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`} style={{height: footerHeightAndWidth ?'68vh':'90vh' }}> 
            <div className="toggle-btn" onClick={toggleSidebar}>
                {isCollapsed ? <ChevronRight className="arrow-icon" /> : <ChevronLeft className="arrow-icon" />}
            </div>
            <ul className="menu-list">
                <li 
                    className={`menu-item ${getActiveClass('/dashboard')}`}
                    onClick={() => handleNavigation('/dashboard')}
                
                >
                     <img src={dashboardIcon} alt="Dashboard" className="icon" />
                     {!isCollapsed && 'Dashboard'}
                </li>
                <li 
                    className={`menu-item ${getActiveClass('/course-list')}`}
                    onClick={() => handleNavigation('/course-list')}
                >
                    <img src={coursesIcon} alt="Courses" className="icon" />
                    {!isCollapsed && 'Courses'}
                </li>
                <li
                    className={`menu-item ${getActiveClass('/learning')}`}
                    onClick={() => handleNavigation('/learning')}
                >
                     <img src={myLearningIcon} alt="My Learning" className="icon" />
                     {!isCollapsed && 'Learning'}
                 </li>
                <li 
                    className={`menu-item ${getActiveClass('/content-management')}`}
                    onClick={() => handleNavigation('/content-management')}
                >
                    <img src={contentLibraryIcon} alt="Content Library" className="icon" />
                    {!isCollapsed && 'Content'}
                </li>
                <li 
                    className={`menu-item ${getActiveClass('/users')}`}
                    onClick={() => handleNavigation('/users')}
                >
                     <img src={userManagementIcon} alt="User Management" className="icon" />
                     {!isCollapsed && 'Users'}
                 </li>
                 <li 
                    className={`menu-item ${getActiveClass('/enrollment')}`}
                    onClick={() => handleNavigation('/enrollment')}
                 >
                     <img src={enrollmentIcon} alt="Enrollment" className="icon" />
                     {!isCollapsed && 'Enroll'}
                 </li>
                 <li 
                    className={`menu-item ${getActiveClass('/reports')}`}
                    onClick={() => handleNavigation('/reports')}
                 >
                     <img src={reportsIcon} alt="Reports" className="icon" />
                     {!isCollapsed && 'Reports'}
                 </li>
            </ul>
        </div>
    );
};

export default Sidebar;

