// import React from 'react';
// import '../assets/Css//PrivacySettings.css';
// import SettingsSidebar from '../Sidebar/settingssidebar';

// const PrivacySettings = () => {
//   return (
//     <div style={{ display: 'flex', width: '100%' }}>
//       <SettingsSidebar />
//     <div className="PrivacySettings-container">
//       <h2 className="PrivacySettings-title">Privacy & Security</h2>
//       <p className="PrivacySettings-subtitle">Recommendations to help you keep your account secure</p>
//       <hr className="PrivacySettings-divider" />
//       <div className="PrivacySettings-section">
//         <div className="PrivacySettings-sectionContent">
//           <h3 className="PrivacySettings-sectionTitle">Email address</h3>
//           <p className="PrivacySettings-sectionDescription">The email address associated with your account</p>
//         </div>
//         <div className="PrivacySettings-sectionAction">
//           <span>jagannathamnithish.07@gmail.com</span> 
//           <button className="PrivacySettings-editButton">Edit</button>
//         </div>
//       </div>
      
//       <div className="PrivacySettings-section">
//         <div className="PrivacySettings-sectionContent">
//           <h3 className="PrivacySettings-sectionTitle">Password</h3>
//           <p className="PrivacySettings-sectionDescription">Setup a unique password to protect your account</p>
//         </div>
//         <div className="PrivacySettings-sectionAction">
//           <button className="PrivacySettings-editButton">Change password</button>
//         </div>
//       </div>
      
//       <div className="PrivacySettings-section">
//         <div className="PrivacySettings-sectionContent">
//           <h3 className="PrivacySettings-sectionTitle">2-step verification</h3>
//           <p className="PrivacySettings-sectionDescription">Make your account extra secure. Along with your password, you'll need to enter a code</p>
//         </div>
//         <div className="PrivacySettings-sectionAction">
//   <button className="toggle-button"></button>
// </div>

//       </div>
//     </div>
//     </div>
//   );
// };

// export default PrivacySettings;


import React, { useState } from 'react';
import '../assets/Css/PrivacySettings.css';
import SettingsSidebar from '../Sidebar/settingssidebar';
import editicon from '../assets/Images/privacy-setting-edit-icon.png'

const PrivacySettings = () => {
  const [isTwoStepVerificationActive, setIsTwoStepVerificationActive] = useState(false);

  const handleToggle = () => {
    setIsTwoStepVerificationActive(!isTwoStepVerificationActive);
  };

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <SettingsSidebar />
      <div className="PrivacySettings-container">
        <h2 className="PrivacySettings-title">Privacy & Security</h2>
        <p className="PrivacySettings-subtitle">Recommendations to help you keep your account secure</p>
        <hr className="PrivacySettings-divider" />
        <div className="PrivacySettings-section">
          <div className="PrivacySettings-sectionContent">
            <h3 className="PrivacySettings-sectionTitle">Email address</h3>
            <p className="PrivacySettings-sectionDescription">The email address associated with your account</p>
          </div>
          <div className="PrivacySettings-sectionAction">
            <span className='PrivacySettings-gmail_heading'>charanvenkatateja.07@gmail.com</span>
            <button className="PrivacySettings-editButton">Edit <img src={editicon} alt="edit-icon" className="PrivacySettings-editButton-icon" />
            </button>
          </div>
        </div>
        
        <div className="PrivacySettings-section">
          <div className="PrivacySettings-sectionContent">
            <h3 className="PrivacySettings-sectionTitle">Password</h3>
            <p className="PrivacySettings-sectionDescription">Setup a unique password to protect your account</p>
          </div>
          <div className="PrivacySettings-sectionAction">
            <button className="PrivacySettings-changepassword">Change password</button>
          </div>
        </div>
        
        <div className="PrivacySettings-section">
          <div className="PrivacySettings-sectionContent">
            <h3 className="PrivacySettings-sectionTitle">2-step verification</h3>
            <p className="PrivacySettings-sectionDescription">Make your account extra secure. Along with your password, you'll need to enter a code</p>
          </div>
          <div className="PrivacySettings-sectionAction">
            <button 
              className={`toggle-button ${isTwoStepVerificationActive ? 'active' : ''}`} 
              onClick={handleToggle}
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacySettings;
