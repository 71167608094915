import React, { useState, useEffect } from 'react';
import '../assets/Css/Header1.css';
import homeIcon from '../assets/Images/home-2.png';
import Nextarrowicon from '../assets/Images/ArrowforNext.png';
import backarrowicon from '../assets/Images/leftarrow.png';
import hidePrev from '../assets/Images/hide_prev_btn.png';
import hideNext from '../assets/Images/hide_next_btn.png';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import logout from '../assets/Images/logout@2x.png';
import { setTopicId, setMediaContentId, setLabConfigId, setQuizConfigId, setNotesId, setVideoId, setPowerPointId, setTopicTitle, setModuleId, setModuleTitle } from "../actions/apisIdsActions";
import { adminaxios } from '../axios';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function Header1() {
  const [showExitPopup, setShowExitPopup] = useState(false);
  const [modulesArrayData, setModulesArrayData] = useState([]);
  const [topicsArrayData, setTopicsArrayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const courseId = useSelector((state) => state.dynamicApiId.courseId);
  const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const dispatch = useDispatch();
  const location = useLocation();

  const CourseTitle = useSelector((state) => state.dynamicApiId.courseTitle);
  const moduleTitle = useSelector((state) => state.dynamicApiId.moduleTitle);
  const topicTitle = useSelector((state) => state.dynamicApiId.topicTitle);

  const ProgressCircle = ({ progress }) => {
    const radius = 20;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;

    return (
      <svg className="header1-progress-circle" width="50" height="50"> {/* Increased width/height for more space */}
        <circle
          className="header1-progress-background"
          cx="25"  // Adjusted to new center
          cy="25"  // Adjusted to new center
          r={radius * 0.75}  // Adjust radius to 75% of the new radius
          strokeWidth="3.5"
        />
        <circle
          className="header1-progress-value"
          cx="25"
          cy="25"
          r={radius * 0.75}
          strokeWidth="3.5"
          strokeDasharray={circumference * 0.75}
          strokeDashoffset={offset * 0.75}
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dy=".5em"  // Adjusted dy for vertical alignment, increasing the gap
          className="header1-progress-text"
          fontSize="10px"  // You can adjust this as per your need
        >
          {`${progress}%`}
        </text>
      </svg>
    );
};



  useEffect(() => {
    setLoading(true);
    adminaxios
      .get(`/courses/${courseId}`)
      .then((response) => {
        const modules = response.data.data.module_details;
        setModulesArrayData(modules);

        const selectedModule = modules.find(module => module._id === selectedModuleId);
        if (selectedModule) {
          setTopicsArrayData(selectedModule.topics);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [courseId, selectedModuleId]);

  const handleToHome = () => {
    setShowExitPopup(true);
  };

  const handleExitCourse = () => {
    navigate('/course-list');
  };

  const handleContinueCourse = () => {
    setShowExitPopup(false);
  };

  const courseTitleHandle = () => {
    navigate(`/course-details/${courseId}`);
  };

  const handleTopicCombinePage = () =>{
    navigate(`/combine/${courseId}`)
  }

  const selectedTopicId = useSelector((state) => state.dynamicApiId.topicId);
  const storeQuizConfigId = useSelector((state) => state.dynamicApiId.quizConfigId);
  const storeLabConfigId = useSelector((state) => state.dynamicApiId.labConfigId);
  const storeNotesId = useSelector((state) => state.dynamicApiId.notesId);
  const storeVideoId = useSelector((state) => state.dynamicApiId.videoId)
  console.log('storeLabConfigId',storeLabConfigId);
  console.log(' storeNotesId ', storeNotesId );
  console.log('storeQuizConfigId',storeQuizConfigId);
  console.log('storeVideoId',storeVideoId);


  const topicsIndex = topicsArrayData.map(topic => topic._id);
  const currentTopicIndex = topicsIndex.indexOf(selectedTopicId);
  const modulesIndex = modulesArrayData.map(module => module._id);
  const currentModuleIndex = modulesIndex.indexOf(selectedModuleId);

  const hidePreviousImage =
  location.pathname.includes('/combine/') &&
  currentModuleIndex === 0 &&
  currentTopicIndex === 0;

const hideNextImage =
  (location.pathname.includes('/lab-list/') && currentModuleIndex === modulesArrayData.length - 1 && currentTopicIndex === topicsArrayData.length - 1) ||
  (location.pathname.includes('/quiz/') && currentModuleIndex === modulesArrayData.length - 1 && currentTopicIndex === topicsArrayData.length - 1 && storeLabConfigId === null) ||
  (location.pathname.includes('/notes/') && currentModuleIndex === modulesArrayData.length - 1 && currentTopicIndex === topicsArrayData.length - 1 && storeLabConfigId === null && storeQuizConfigId === null) ||
  (location.pathname.includes('/combine/') && currentModuleIndex === modulesArrayData.length - 1 && currentTopicIndex === topicsArrayData.length - 1 && storeLabConfigId === null && storeQuizConfigId === null && storeNotesId === null);


const hideBothImages = (location.pathname.includes('/quiz-page/')) ||  (location.pathname.includes('/quiz-results/')) ;

  const handleToNextTopicInCombine = () => {
    dispatch({ type: 'RESET_CONTENT_TOGGLE' });
    if (storeNotesId !== null || storeQuizConfigId !== null || storeLabConfigId !== null ) {
      if (storeNotesId !== null && location.pathname.includes('/combine/')) {
        navigate(`/notes/${courseId}`);
      } else if (storeQuizConfigId !== null && location.pathname.includes('/notes/')) {
        navigate(`/quiz/${courseId}`);
      } else if (storeLabConfigId !== null && location.pathname.includes('/quiz/')) {
        navigate(`/lab-list/${courseId}`);
      } else if (location.pathname.includes('/notes/') && storeLabConfigId === null && storeQuizConfigId === null) {
        handleNextTopicDetails();
      } else if (location.pathname.includes('/quiz/') && storeLabConfigId === null) {
        handleNextTopicDetails();
      } else if (location.pathname.includes('/lab-list/')) {
        handleNextTopicDetails();
      }
    } else if (storeNotesId === null && storeQuizConfigId === null && storeLabConfigId === null) {
      handleNextTopicDetails();
    }
  };


  

  const handleNextTopicDetails = () => {
    if (currentTopicIndex < topicsIndex.length - 1) {
      const nextTopic = topicsArrayData[currentTopicIndex + 1];
      const nextTopicId = nextTopic._id;
      const nextTopicTitle = nextTopic.title;
      const nextMediaContentId = nextTopic.media_content_ids[0]?._id;
      const nextMediaContent = nextTopic.media_content_ids[0];
      const nextVideoId = nextMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
      const nextNotesId = nextMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
      const nextPptId = nextMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;

      dispatch(setNotesId(nextNotesId || null));
      dispatch(setVideoId(nextVideoId || null));
      dispatch(setTopicId(nextTopicId || null));
      dispatch(setMediaContentId(nextMediaContentId || null));
      dispatch(setPowerPointId(nextPptId || null));
      dispatch(setTopicTitle(nextTopicTitle || null));

      let labId = '', quizId = '';
      nextTopic.question_bank_configs.forEach(config => {
        if (config.type === 'Lab') {
          labId = config._id;
        } else if (config.type === 'Quiz') {
          quizId = config._id;
        }
      });

      dispatch(setLabConfigId(labId ? labId : null));
      dispatch(setQuizConfigId(quizId ? quizId : null));

      navigate(`/combine/${courseId}`);
    } else if (currentTopicIndex === topicsIndex.length - 1 && currentModuleIndex < modulesIndex.length - 1) {
      const nextModule = modulesArrayData[currentModuleIndex + 1];
      const nextModuleId = nextModule._id;
      const nextModuleTitle = nextModule.title;
      const nextTopic = nextModule.topics[0];
      const nextTopicId = nextTopic._id;
      const nextTopicTitle = nextTopic.title;
      const nextMediaContentId = nextTopic.media_content_ids[0]?._id;
      const nextMediaContent = nextTopic.media_content_ids[0];
      const nextVideoId = nextMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
      const nextNotesId = nextMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
      const nextPptId = nextMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;

      dispatch(setModuleId(nextModuleId || null));
      dispatch(setModuleTitle(nextModuleTitle || null));
      dispatch(setNotesId(nextNotesId || null));
      dispatch(setVideoId(nextVideoId || null));
      dispatch(setTopicId(nextTopicId || null));
      dispatch(setMediaContentId(nextMediaContentId || null));
      dispatch(setPowerPointId(nextPptId || null));
      dispatch(setTopicTitle(nextTopicTitle || null));

      let labId = '', quizId = '';
      nextTopic.question_bank_configs.forEach(config => {
        if (config.type === 'Lab') {
          labId = config._id;
        } else if (config.type === 'Quiz') {
          quizId = config._id;
        }
      });

      dispatch(setLabConfigId(labId ? labId : null));
      dispatch(setQuizConfigId(quizId ? quizId : null));

      navigate(`/combine/${courseId}`);
    }
  };

  const handleToPreviousTopicInCombine = () => {
    dispatch({ type: 'RESET_CONTENT_TOGGLE' });
    if (storeLabConfigId !== null && location.pathname.includes('/lab-list/')) {
       
        navigate(`/quiz/${courseId}`);
    } else if (storeQuizConfigId !== null && location.pathname.includes('/quiz/')) {
       
        navigate(`/notes/${courseId}`);
    } else if (storeNotesId !== null && location.pathname.includes('/notes/')) {
       
        navigate(`/combine/${courseId}`);
    } else if (location.pathname.includes('/combine/')) {
        
        if (currentTopicIndex > 0) {
            handlePreviousTopicDetails(); 
        } else if (currentModuleIndex > 0) {
            handlePreviousTopicDetails(); 
        }
    }
};
 


  const handlePreviousTopicDetails = () => {
    if (currentTopicIndex > 0) {
      const prevTopic = topicsArrayData[currentTopicIndex - 1];
      const prevTopicId = prevTopic._id;
      const prevTopicTitle = prevTopic.title;
      const prevMediaContentId = prevTopic.media_content_ids[0]?._id;
      const prevMediaContent = prevTopic.media_content_ids[0];
      const prevVideoId = prevMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
      const prevNotesId = prevMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
      const prevPptId = prevMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;

      dispatch(setNotesId(prevNotesId));
      dispatch(setVideoId(prevVideoId));
      dispatch(setTopicId(prevTopicId));
      dispatch(setMediaContentId(prevMediaContentId));
      dispatch(setPowerPointId(prevPptId));
      dispatch(setTopicTitle(prevTopicTitle));

      let labId = '', quizId = '';
      prevTopic.question_bank_configs.forEach(config => {
        if (config.type === 'Lab') {
          labId = config._id;
        } else if (config.type === 'Quiz') {
          quizId = config._id;
        }
      });

      dispatch(setLabConfigId(labId ? labId : null));
      dispatch(setQuizConfigId(quizId ? quizId : null));

      navigate(`/combine/${courseId}`);
    } else if (currentTopicIndex === 0 && currentModuleIndex > 0) {
      const prevModule = modulesArrayData[currentModuleIndex - 1];
      const prevModuleId = prevModule._id;
      const prevModuleTitle = prevModule.title;
      const prevTopic = prevModule.topics[prevModule.topics.length - 1];
      const prevTopicId = prevTopic._id;
      const prevTopicTitle = prevTopic.title;
      const prevMediaContentId = prevTopic.media_content_ids[0]?._id;
      const prevMediaContent = prevTopic.media_content_ids[0];
      const prevVideoId = prevMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
      const prevNotesId = prevMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
      const prevPptId = prevMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;

      dispatch(setModuleId(prevModuleId));
      dispatch(setModuleTitle(prevModuleTitle));
      dispatch(setNotesId(prevNotesId));
      dispatch(setVideoId(prevVideoId));
      dispatch(setTopicId(prevTopicId));
      dispatch(setMediaContentId(prevMediaContentId));
      dispatch(setPowerPointId(prevPptId));
      dispatch(setTopicTitle(prevTopicTitle));

      let labId = '', quizId = '';
      prevTopic.question_bank_configs.forEach(config => {
        if (config.type === 'Lab') {
          labId = config._id;
        } else if (config.type === 'Quiz') {
          quizId = config._id;
        }
      });

      dispatch(setLabConfigId(labId ? labId : null));
      dispatch(setQuizConfigId(quizId ? quizId : null));

      navigate(`/combine/${courseId}`);
    }
  };

  return (
    <div className="header1-container">
      <div className="header1-left">
        <img src={homeIcon} alt="Home" className="header1-home-icon" onClick={handleToHome} />
        <div className="header1-divider"></div>
        <div className="header1-text">
          <h4 className="header1-title" onClick={courseTitleHandle}>{CourseTitle}</h4>
          <p className="header1-subtitle">{`${moduleTitle}`} / <span onClick={handleTopicCombinePage} style={{cursor:'pointer'}}>{`${topicTitle}`}</span></p>
        </div>
      </div>
       
      <div className="header1-center">
          {!hideBothImages && (
            <>
              { !hidePreviousImage ? (
                <img src={backarrowicon} alt="Back" className="header1-prev_and_next" onClick={handleToPreviousTopicInCombine} />
              ): (
                <img src={hidePrev} alt='back' className="header1-prev_and_next_hide"  />
              )}

              

              { !hideNextImage ? (
                <img src={Nextarrowicon} alt="Next" className="header1-prev_and_next" onClick={handleToNextTopicInCombine} />
              ):(
                <img src={hideNext} alt='back' className="header1-prev_and_next_hide"  />
              )}
            </>
          )}
      </div>
      <div className='header1-last'>
        <div className="header1-text">
          <h4 className="header1-title">Course Progress</h4>
          <p className="header1-subtitle">Total In Percentage</p>
        </div>
        <div className="header1-divider"></div>
         <div className="header1-right">
                <ProgressCircle progress={20} />
         </div>

      </div>



      {showExitPopup && (
        <div className="course-exit-popup-container">
          <div className="course-exit-popup">
            <p>Are you sure, you want to exit the Course?</p>
            <div className="course-exit-buttons">
              <button className='course-exit-quiz-button' onClick={handleExitCourse}>Exit Course</button>
              <button className='course-exit-continue-button' onClick={handleContinueCourse}>Continue Course</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header1;