import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../assets/Css/Signup.css';
import Circle1 from '../assets/Images/Ellipse_Full.png';
import Circle2 from '../assets/Images/Ellipse_Half.png';
import companyLogo from '../assets/Images/Company_logo.png';
import eyeOpen from '../assets/Images/eye.png';
import eyeClose from '../assets/Images/eye-slash.png';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { setCredentials } from '../reducers/authSlice';


const Signup = () => {
    const [togglePassword, setTogglePassword] = useState(false);
    const [togglePasswordTwo, setTogglePasswordTwo] = useState(false);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSignup = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            setLoading(false);
            return;
        }

        const payload = {
            username,
            email,
            password,
            password2: confirmPassword,
        };

        try {
            const response = await axios.post('http://lms.nquantum.in:8000/api/register/ ', payload);
            console.log('Signup success:', response.data);
            navigate.push('/verifyEmail');
        } catch (err) {
            if (err.response && err.response.data) {
                console.error('Signup error:', err.response.data);
                const { data } = err.response;
                if (data.email) {
                    setEmailError(data.email[0]);
                }
                if (data.username) {
                    setUsernameError(data.username[0]);
                }
            } else {
                console.error('Signup error:', err);
            }
            setLoading(false);
        }
    };

    return (
        <div className="signup-container">
            <div className='signup-left'>
            <div className="signup-logo">
                <img src={companyLogo} alt="company-logo" />
            </div>
            <div className="signup-description">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                a type specimen book.
            </div>
            </div>
            <div className='signup-right'>
            <div className="signup-circles">
                <img src={Circle1} alt="Circle 1" className="signup-circle signup-circle1" />
                <img src={Circle2} alt="Circle 2" className="signup-circle signup-circle2" />
            </div>
            <div className="signup-box">
                <h2>Sign Up</h2>
                <form onSubmit={handleSignup}>
                    <div className="signup-form-group">
                        <input
                            className="signup-form-control"
                            type="text"
                            placeholder="Username"
                            required
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                        />
                    </div>
                    <div className="signup-form-group">
                        <input
                            className="signup-form-control"
                            type="email"
                            placeholder="Email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </div>
                    <div className="signup-form-group">
                        <input
                            className="signup-form-control"
                            type={togglePassword ? 'text' : 'password'}
                            placeholder="Password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                        <div className="register-pw-one-position" onClick={() => setTogglePassword(!togglePassword)}>
                            <span>
                                <img src={togglePassword ? eyeOpen : eyeClose} alt="icon" className="signup-eye-icon" />
                            </span>
                        </div>
                    </div>
                    <div className="signup-form-group">
                        <input
                            className="signup-form-control"
                            type={togglePasswordTwo ? 'text' : 'password'}
                            placeholder="Confirm-Password"
                            required
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                        />
                        <div className="register-pw-two-position" onClick={() => setTogglePasswordTwo(!togglePasswordTwo)}>
                            <span>
                                <img src={togglePasswordTwo ? eyeOpen : eyeClose} alt="icon" className="signup-eye-icon-2" />
                            </span>
                        </div>
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                    {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>}
                    <button type="submit" className="register-button" disabled={loading}>
                        Sign up {loading && <CircularProgress size={8} color="inherit" style={{ marginLeft: '10px', marginTop: '3px' }} />}
                    </button>
                </form>
                <div className="signup-link">
                    Already registered? <Link to="/">Sign in</Link> here
                </div>
            </div>
            </div>
        </div>
    );
};

export default Signup;

