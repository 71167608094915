import React from 'react'

const ContentLibrary = ({files}) => {
  return (
    <div className="content-library">
      <h2>Content Library</h2>
      <ul>
        {files ? files.map((file, index) => (
          <li key={index}>
            <p>Name: {file.name}</p>
            <p>Type: {file.type}</p>
            <p>Size: {(file.size / 1024).toFixed(2)} KB</p>
            {file.type.startsWith('image/') && (
              <img src={file.content} alt={file.name} style={{ maxWidth: '100px' }} />
            )}
            {file.type === 'application/pdf' && (
              <embed src={file.content} type="application/pdf" width="100px" height="100px" />
            )}
            {file.type === 'text/plain' && (
              <iframe src={file.content} title={file.name} width="100px" height="100px"></iframe>
            )}
          </li>
        )) : 'Loading....'}
      </ul>
    </div>
  )
}

export default ContentLibrary
