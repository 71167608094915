import { combineReducers } from 'redux';
import contentReducer from './contentReducer';
import sidebarReducer from './sidebarReducer';
import basicSideBar from './basicSidebarReducer'
import authReducer from './authSlice'
import apisIdsReducer from './apisIdsReducer'
import footerReducer from './footerReducer';

const rootReducer = combineReducers({
  content: contentReducer,
  sidebar : sidebarReducer,
  basicSidebar:basicSideBar,
  auth: authReducer,
  dynamicApiId : apisIdsReducer,
  footerAlign : footerReducer,

  
}); 


export default rootReducer;
