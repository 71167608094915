import React, { useEffect, useState } from "react";
import { adminaxios } from "../axios";
import { useSelector } from "react-redux";
import { useNavigate,useParams } from "react-router-dom";
import "../assets/Css/ModuleDetailsDisplay.css" // Assuming you have a separate CSS file for styling

const ModuleDetailsDisplay = () => {
  const [moduleData, setModuleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  // Fetch module data from the API
  useEffect(() => {
    adminaxios
      .get(`/modules/${selectedModuleId}/`)
      .then((response) => {
        setModuleData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching module data", error);
        setLoading(false);
      });
  }, []);

  const handleToCombinePage = () => {
    navigate(`/combine/${courseId}`)
  }

  if (loading) {
    return <div className="loading">Loading module data...</div>;
  }

  if (!moduleData) {
    return <div className="error">Error loading data.</div>;
  }

  return (
    <div className="main-module-container">
        <button className="module-container-button" onClick={handleToCombinePage}>{`Go To ${moduleData.title}`}</button>
    <div className="module-container">
      <h1 className="module-title">{moduleData.title}</h1>
      <p className="module-description">{moduleData.description}</p>
      
      {/* Display Metadata */}
      <div className="module-metadata">
        <strong>Category:</strong> {moduleData.metadata.category.name} <br />
        <strong>Sub-Category:</strong> {moduleData.metadata.category.sub_category.name} <br />
        <strong>Tags:</strong> {moduleData.metadata.tags.join(", ")} <br />
        <strong>Created By:</strong> {moduleData.metadata.created_by} <br />
        <strong>Created At:</strong> {new Date(moduleData.metadata.created_at).toLocaleDateString()} <br />
      </div>

      {/* Display Topics */}
      <div className="module-topics">
        <h2>Topics Covered</h2>
        {moduleData.topics.map((topic, index) => (
          <div key={index} className="topic-card">
            <h3 className="topic-title">{topic.title}</h3>
            <p className="topic-tags"><strong>Tags:</strong> {topic.metadata.tags.join(", ")}</p>

            {/* Question Bank Configurations */}
            <div className="quiz-info">
              <strong>Quiz Information:</strong> 
              <p>{topic.question_bank_configs[0].instructions}</p>
              <p><strong>Time Limit:</strong> {topic.question_bank_configs[0].time_limit_min} minutes</p>
              <p><strong>Quiz Difficulty:</strong> {topic.question_bank_configs[0].intensity_level.join(", ")}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default ModuleDetailsDisplay;
