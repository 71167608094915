
import React, { useState } from 'react'
import '../assets/Css/ChangePassword.css'
import eyeOpen from '../assets/Images/eye.png'
import eyeClose from '../assets/Images/eye-slash.png'
import SettingsSidebar from '../Sidebar/settingssidebar'
import LeftArrow from '../assets/Images/arrow-left.png'
const ChangePassword = () => {
    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
      const [togglePassword, setTogglePassword] = useState(false);
      const [togglePasswordTwo, setTogglePasswordTwo] = useState(false);
      const [togglePasswordThree, setTogglePasswordThree] = useState(false);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setPasswords(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Add validation and submission logic here
        console.log('Passwords:', passwords);
      };
    
      return (
      <div style={{display:'flex',height:'100vh',overflowY:'scroll',width:'100%'}}>
       <SettingsSidebar /> 
      <div className='change-pass-main-cont'>
         <h2 className='change-pass-heading'>
          <span>
            <img src={LeftArrow} alt="left arrow" className='change-pass-left-arrow' />
          </span>
          Password</h2>
         <p className='change-pass-para'>Manage settings for your account password</p>
         <div className='br-line-change-pass'></div>
        <div className="change-password-form">
          <form onSubmit={handleSubmit}>
            <div className="input-group-change-pass">
              <label className='label-change-password'>Current password</label>
                <div className='change-pass-input-cont'>
                    <input
                        type={togglePassword ? "text" : "password"}
                        name="currentPassword"
                        value={passwords.currentPassword}
                        onChange={handleChange}
                    />
                    <div onClick={() => setTogglePassword(!togglePassword)}>
                            <span >
                                <img src={togglePassword?eyeOpen:eyeClose} alt='icon' className='change-pass-eye-icon' />
                            </span>
                    </div>
                </div>    
            </div>
            <div className="input-group-change-pass">
              <label className='label-change-password'>New password</label>
                <div className='change-pass-input-cont'>
                    <input
                        type={togglePasswordTwo ? "text" : "password"}
                        name="newPassword"
                        value={passwords.newPassword}
                        onChange={handleChange}
                    />
                    <div onClick={() => setTogglePasswordTwo(!togglePasswordTwo)}>
                        <span >
                            <img src={togglePasswordTwo?eyeClose:eyeOpen} alt='icon' className='change-pass-eye-icon' />
                        </span>
                    </div>
                </div>
            </div>
            <div className="input-group-change-pass">
              <label className='label-change-password'>Confirm password</label>
                    <div className='change-pass-input-cont'> 
                        <input
                            type={togglePasswordThree ? "text" : "password"}
                            name="confirmPassword"
                            value={passwords.confirmPassword}
                            onChange={handleChange}
                        />
                        <div onClick={() => setTogglePasswordThree(!togglePasswordThree)}>
                            <span >
                                <img src={togglePasswordThree?eyeClose:eyeOpen} alt='icon' className='change-pass-eye-icon' />
                            </span>
                       </div>
                   </div> 
            </div>
            <div className="buttons">
              <button type="button" onClick={() => setPasswords({currentPassword: '', newPassword: '', confirmPassword: ''})}>Cancel</button>
              <button type="submit">Update</button>
            </div>
          </form>
        </div>
    </div>
    </div>  
      );
}

export default ChangePassword;
