// import React, {useState} from 'react';
// import '../assets/Css/OtpConfirm.css';
// import Circle1 from '../assets/Images/Ellipse_Full.png';
// import Circle2 from '../assets/Images/Ellipse_Half.png';
// import OtpConfirmImg from '../assets/Images/OtpConfirmImg.png';
// import {useNavigate} from 'react-router-dom';
// import { CircularProgress } from '@mui/material';
// import axios from 'axios';

// const ForgetPassword = () => {
  
//   const [otp, setOtp] = useState(new Array(6).fill(""));
//   const [otpError,setOtpError] = useState('');
//   const [loading, setLoading] = useState(false);
 
//   const navigate = useNavigate()
//   const userID = localStorage.getItem('newUSerId');

//   const handleNewPassword = async (event) => {
//     event.preventDefault();
//     setLoading(true);
    

//     const payload = {
//         "user_id":userID,
//         "otp":otp,
//     };

//     try {
//       const response = await axios.post('/api/password-reset/verify-otp/ ', payload);
//       console.log('Signup success:', response.data);
//       navigate('/setNewPassword');
//       // Handle success here, e.g., redirecting to another page or showing a success message
//     } catch (err) {
//       if (err.response && err.response.data) {
//         console.error('Signup error:', err.response.data);
//         const { data } = err.response;
//         if (data.otp) {
//           setOtpError(data.otp[0]);
//         }
//       } else {
//         console.error('Signup error:', err);
//       }
      
//     }

//     setLoading(false);
//   };


//   const handleChange = (element, index) => {
//     if (isNaN(element.value)) return false; 
//     const newOtp = [...otp];
//     newOtp[index] = element.value;
//     setOtp(newOtp);

//     if (element.nextSibling && element.value) {
//       element.nextSibling.focus();
//     }
//   };

//   return (
//     <div className="OtpConfirm-container">
//       <div className="OtpConfirm-left">
//         <img src={OtpConfirmImg} alt='OtpConfirmImg' className='OtpConfirm-left-img-setNewPassword' />
//       </div>
//       <div className="OtpConfirm-right">
//         <div className="OtpConfirm-circles">
//           <img src={Circle1} alt="Circle 1" className="OtpConfirm-circle  OtpConfirm-circle1" />
//           <img src={Circle2} alt="Circle 2" className="OtpConfirm-circle  OtpConfirm-circle2" />
//         </div>
//         <div className="OtpConfirm-box" >
//           <h2>Enter verification code</h2>
//           <p className='OtpConfirm-para-otp-2'>We have sent 6 digits of OTP to your registered email address</p>
//           <form onSubmit={handleNewPassword} >
//             <div className="OtpConfirm-form-group-otpverify"> 
//             {otp.map((data, index) => (
//                 <input
//                   className="OtpConfirm-otp-field"
//                   type="text"
//                   name="otp"
//                   maxLength="1"
//                   key={index}
//                   value={data}
//                   onChange={e => handleChange(e.target, index)}
//                   onFocus={e => e.target.select()}
//                   autoComplete="off"
//                 />
//               ))}
//             </div>
//             {otpError && <p style={{color:'red'}}>{otpError}</p>}
//             <p className='OtpConfirm-para-otp'>If you haven't receive a code,<button className='OtpConfirm-otp-button-resend'>Resend</button></p>
//             <button className='OtpConfirm-button-email-forget'>
//              Continue
//              {loading ? <CircularProgress size={15} color="inherit" style={{ marginLeft: "10px", marginTop: "3px" }} /> : ''}
//             </button>
//           </form>
          
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ForgetPassword;








import React, { useState } from 'react';
import '../assets/Css/OtpConfirm.css';
import Circle1 from '../assets/Images/Ellipse_Full.png';
import Circle2 from '../assets/Images/Ellipse_Half.png';
import OtpConfirmImg from '../assets/Images/OtpConfirmImg.png';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import axios from 'axios';

const ForgetPassword = () => {
  
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otpError, setOtpError] = useState('');
  const [loading, setLoading] = useState(false);
 
  const navigate = useNavigate();
  const userID = localStorage.getItem('newUSerId');

  const handleNewPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    
    const payload = {
      "user_id": userID,
      "otp": otp.join(""),
    };

    try {
      const response = await axios.post('/api/password-reset/verify-otp/', payload);
      console.log('Signup success:', response.data);
      navigate('/setNewPassword');
    } catch (err) {
      if (err.response && err.response.data) {
        console.error('Signup error:', err.response.data);
        const { data } = err.response;
        if (data.otp) {
          setOtpError(data.otp[0]);
        }
      } else {
        console.error('Signup error:', err);
      }
    }

    setLoading(false);
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (element, index) => {
    if (element.key === "Backspace") {
      if (otp[index] === "") {
        if (element.target.previousSibling) {
          element.target.previousSibling.focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  return (
    <div className="OtpConfirm-container">
      <div className="OtpConfirm-left">
        <img src={OtpConfirmImg} alt='OtpConfirmImg' className='OtpConfirm-left-img-setNewPassword' />
      </div>
      <div className="OtpConfirm-right">
        <div className="OtpConfirm-circles">
          <img src={Circle1} alt="Circle 1" className="OtpConfirm-circle OtpConfirm-circle1" />
          <img src={Circle2} alt="Circle 2" className="OtpConfirm-circle OtpConfirm-circle2" />
        </div>
        <div className="OtpConfirm-box">
          <h2>Enter verification code</h2>
          <p className='OtpConfirm-para-otp-2'>We have sent 6 digits of OTP to your registered email address</p>
          <form onSubmit={handleNewPassword}>
            <div className="OtpConfirm-form-group-otpverify"> 
              {otp.map((data, index) => (
                <input
                  className="OtpConfirm-otp-field"
                  type="text"
                  name="otp"
                  maxLength="1"
                  key={index}
                  value={data}
                  onChange={e => handleChange(e.target, index)}
                  onKeyDown={e => handleKeyDown(e, index)}
                  onFocus={e => e.target.select()}
                  autoComplete="off"
                />
              ))}
            </div>
            {otpError && <p style={{color:'red'}}>{otpError}</p>}
            <p className='OtpConfirm-para-otp'>If you haven't received a code,<button className='OtpConfirm-otp-button-resend'>Resend</button></p>
            <button className='OtpConfirm-button-email-forget'>
              Continue
              {loading ? <CircularProgress size={15} color="inherit" style={{ marginLeft: "10px", marginTop: "3px" }} /> : ''}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
