import React , { useState }  from 'react'
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import Sidebar2 from '../Sidebar/Sidebar2';


const Video = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <div className='video_cont' style={{display:'flex',justifyContent:'center',height:'90vh'}}>
        <ReactPlayer
          url='https://lms-dataworks.s3.amazonaws.com/2024-01-03+11.13+Free+Python+BootCamp.mp4'
          width="960px"
          height="480px"
          controls
          onEnded={() => setIsModalOpen(true)}
        />
        
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Video Ended"
          style={{ 
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '400px', 
              height: '200px', 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }
          }}
        >
          <h2>Video Completed</h2>
          <h3>Go to quiz</h3>
          <button onClick={() => setIsModalOpen(false)}>Close</button>
        </Modal>
      </div>
    );
}

export default Video
