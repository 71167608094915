import React from 'react'

const ContentLab = () => {
  return (
    <div>
      ContentLab
    </div>
  )
}

export default ContentLab
