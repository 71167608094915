import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/Css/Assessment.css';
import Timer from '../assets/Images/Quiz-Timer.png';  
import Question from '../assets/Images/Quiz-Question.png'; 
import { Oval } from 'react-loader-spinner';

const Quiz = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();  

  const startAssessment = () => {
    navigate('/assessment-page');
  };

  useEffect(() => { 
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // if (loading) {
  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
  //        <Oval 
  //           height={50} 
  //           width={50} 
  //           color="#000080" 
  //           ariaLabel="loading"
  //        />
  //     </div>
  //   );
  // }

  return (
    <div className="Assessment-homepage-container">
      <div className="Assessment-homepage-box">
        <div className="Assessment-homepage-headerPage">
         <p className="Assessment-paragraph">Assessment 1</p>
          <h2 className="Assessment-homepage-h2">Python</h2>
          <div className="Assessment-homepage-info-wrapper">
            <div className="Assessment-homepage-info">
              <div className="Assessment-homepage-info-item">
                <img src={Timer} alt="No Time Limit" className="Assessment-homepage-icon"/> 
                <div className="Assessment-homepage-info-text">
                  <p className="Assessment-homepage-duration">Test Duration</p>
                  <p className="Assessment-homepage-value">90 Minutes</p>
                </div>
              </div>
              <div className="Assessment-homepage-info-item">
                <img src={Question} alt="4 Questions" className="Assessment-homepage-icon"/> 
                <div className="Assessment-homepage-info-text">
                  <p className="Assessment-homepage-questions">Questions</p>
                  <p className="Assessment-homepage-value">90</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Assessment-homepage-instructions">
          <strong>Instructions</strong> 
          <p className="Assessment-homepage-paragraph">FCC officials are divided over whether to require disclosure of AI-generated content in political ads. FCC Chair Rosenworcel supports the proposal, citing concerns about misleading deep fakes. Vice Chair Weitzman agrees.</p>
        </div>
        <div className="Assessment-homepage-skills-wrapper">
          <div className="Assessment-homepage-skills-box">
            <p className="Assessment-homepage-skill">Skills</p>
            <p className="Assessment-homepage-skill-language">HTML,CSS</p>
          </div>
          <div className="Assessment-homepage-sub-skills-box">
            <p className="Assessment-homepage-sub-skill">Sub-skills</p>
            <p className="Assessment-homepage-skill-basic">Basics</p>
          </div>
        </div>

        <button className="Assessment-homepage-start-quiz-btn" onClick={startAssessment}>Start Assessment</button>
      </div>
    </div>
  );
}

export default Quiz;
