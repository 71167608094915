const initialState = {
    contentToggle: false
  };
  
 
  function contentReducer(state = initialState, action) {
    switch (action.type) {
      case 'TOGGLE_CONTENT':
        return {
          ...state,
          contentToggle: !state.contentToggle
        };
      case 'RESET_CONTENT_TOGGLE':
          return {
              ...state,
              contentToggle: false // explicitly setting contentToggle to false
          };  
      default:
        return state;
    } 
  }
  
  export default contentReducer;
  