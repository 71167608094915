import React, { useState } from 'react';
import '../assets/Css/SetNewPassword.css';
import Circle1 from '../assets/Images/Ellipse_Full.png';
import Circle2 from '../assets/Images/Ellipse_Half.png';
import passwordCardImg from '../assets/Images/setNewPAsswordImg.png'
import eyeClose from '../assets/Images/eye-slash.png'
import eyeOpen from '../assets/Images/eye.png'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material';
import axios from 'axios';

const SetNewPassword = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [togglePasswordTwo, setTogglePasswordTwo] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate()
  const userID = localStorage.getItem('newUSerId');

  const handleSetPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    const payload = { 
      "user_id": userID, 
      "new_password": newPassword, 
      "confirm_password": confirmPassword
    }

    try {
      const response = await axios.post('/api/password-reset/change-password/', payload);
      console.log('new_password_success:', response.data);
      
      navigate('/newPasswordSuccess')
     
    } catch (err) {
      console.error('Signup error:', err);
      const { data } = err.response;
        if (data.error) {
          setError(data.error); 
        }
    }

    setLoading(false);
  };

  return (
    <div className="setPassword-login-container">
      <div className="setPassword-login-left">
        <img src={passwordCardImg} alt='notificationImg' className='setPassword-left-img-setNewPassword' />
      </div>
      <div className="setPassword-login-right">
        <div className="setPassword-circles">
          <img src={Circle1} alt="Circle 1" className="setPassword-circle setPassword-circle1" />
          <img src={Circle2} alt="Circle 2" className="setPassword-circle setPassword-circle2" />
        </div>
        <div className="setPassword-login-box" style={{ height: "340px" }}>
          <h2>Create a new password</h2>
          <form onSubmit={handleSetPassword}>
            <div className="setPassword-form-group">
              <label htmlFor='emailLabel' className='setPassword-label_feild_newPassword'>Enter new password</label>
              <input className='setPassword-form-control setPassword-newPassword' type={togglePasswordTwo ? "text" : "password"} id='emailLabel' placeholder="At least 6 digits" required onChange={e => setNewPassword(e.target.value)} defaultValue={newPassword} />
              <div className='setPassword-newPassword-pw-position-two' onClick={() => setTogglePasswordTwo(!togglePasswordTwo)}>
                <span >
                    <img src={togglePasswordTwo ? eyeOpen : eyeClose} alt='icon' className='setPassword-eye-icon' />
                </span>
              </div>
            </div>
            <div className="setPassword-form-group">
              <label htmlFor='emailLabel2' className='setPassword-label_feild_newPassword'>Conform password</label>
              <input className='setPassword-form-control setPassword-newPassword' type={togglePassword ? "text" : "password"} id='emailLabel2' required onChange={e => setConfirmPassword(e.target.value)} defaultValue={confirmPassword} />
              <div className='setPassword-newPassword-pw-position' onClick={() => setTogglePassword(!togglePassword)}>
                <span >
                    <img src={togglePassword ? eyeOpen : eyeClose} alt='icon' className='setPassword-eye-icon' />
                </span>
              </div>
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <button className='setPassword-button-nnewPassword'>
            Confirm
            {loading ? <CircularProgress size={15} color="inherit" style={{ marginLeft: "10px", marginTop: "3px" }} /> : ''}
            </button>
          </form>
          
        </div>

      </div>
    </div>
  );
};

export default SetNewPassword;