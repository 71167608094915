import React, { useState, useEffect, useRef, us } from 'react';
import MonacoEditor from '@monaco-editor/react';
import '../assets/Css/CodeEditor.css';
import { useNavigate } from 'react-router-dom';
import instruction_icon2 from '../assets/Images/Instruction2.png';
import Hint2_icon from '../assets/Images/Hint2.png';
import output_code_icon from '../assets/Images/output_code.png';
import Theme_icon from '../assets/Images/sun.png';
import Solution_icon2 from '../assets/Images/solution3.png';
import Play_icon from '../assets/Images/play.png';
import Backward_icon from '../assets/Images/Backward_icon_3.png';
import Fordward_icon from '../assets/Images/Fordward_icon_3.png';
import output_maximize_icon from '../assets/Images/Output_maximize_icon_2.png';
import close_icon from '../assets/Images/new-lab-close_icon_3.png';
import { adminaxios } from '../axios/index';
import { useParams } from 'react-router-dom';

const CodeEditor = () => {
  const [activeTab, setActiveTab] = useState('challenge');
  const [content, setContent] = useState({ challenge: '', instructions: '', hints: '', solution: '' });
  const [leftWidth, setLeftWidth] = useState(40);
  const [editorWidth, setEditorWidth] = useState(60);
  const [outputWidth, setOutputWidth] = useState(0);
  const [showOutput, setShowOutput] = useState(false);
  const [outputExpanded, setOutputExpanded] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const defaultContent = `print("Try Dataworks.pro")` + `\n`.repeat(22);
  const [showSolution, setShowSolution] = useState(false);
  const [outputs, setOutputs] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const challengeRef = useRef(null);
  const instructionsRef = useRef(null);
  const hintsRef = useRef(null);
  const solutionRef = useRef(null);
  const containerRef = useRef(null);
  const editorRef = useRef(null);
  const [isDraggingLeft, setIsDraggingLeft] = useState(false);
  const [isDraggingEditorOutput, setIsDraggingEditorOutput] = useState(false);
  const { id: courseId } = useParams();
  const navigate = useNavigate();
  const selectedLabId = localStorage.getItem('each_lab_id');

  useEffect(() => {
    // API call to fetch content
    adminaxios.get(`question_bank/${selectedLabId}/`)
      .then(res => {
        const projectLabContent = res.data.project_lab_content;
        setContent({
          title: projectLabContent.title || 'No title available.',
          challenge: projectLabContent.lab_question || 'No challenge available.',
          instructions: projectLabContent.lab_instructions || 'No instructions available.',
          hints: projectLabContent.lab_hints || 'No hints available.',
          solution: projectLabContent.lab_solution || 'No solution available.',
          testCases: projectLabContent.lab_test_cases || []
        });
      })
      .catch(error => console.error('Error fetching content:', error));
  }, []);

  const handleExitLabClick = () => {
    localStorage.removeItem('each_lab_id')
    navigate(`/lab-list/${courseId}`);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case 'challenge':
        if (challengeRef.current) {
          challengeRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'instructions':
        if (instructionsRef.current) {
          instructionsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'hints':
        if (hintsRef.current) {
          hintsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      default:
        break;
    }
  };

  const handleSolutionClick = () => {
    setShowSolution(true);
  };

  const handleCloseSolution = () => {
    setShowSolution(false);
  };




  const runCode = async () => {
    const codeToExecute = editorRef.current.getValue();
    setIsLoading(true);
    setShowOutput(false);

    try {
      const response = await adminaxios.post('/execute/', {
        code: codeToExecute,
        language: 'python',
      });

      if (response.status === 200) {
        setOutputs(prevOutputs => [response.data?.result || 'No output']);
        setIsError(false);
      } else {
        setOutputs(prevOutputs => [response.data?.error || 'An error occurred']);
        setIsError(true);
      }
    } catch (error) {
      setOutputs(prevOutputs => ['An error occurred: ' + error.message]);
      setIsError(true);
    } finally {
      setIsLoading(false);
      setShowOutput(true);
      setLeftWidth(33.33);
      setEditorWidth(33.33);
      setOutputWidth(33.33);
    }
  };

  const toggleTheme = () => {
    // setIsDarkTheme(!isDarkTheme);
  };

  const toggleOutput = () => {
    if (!showOutput) {
      setShowOutput(true);
      setLeftWidth(33.33);
      setEditorWidth(33.33);
      setOutputWidth(33.33);
    }
  };

  const expandOutput = () => {
    setOutputExpanded(!outputExpanded);

    if (!outputExpanded) {
      // Expand the output section
      setLeftWidth(0);
      setEditorWidth(50);
      setOutputWidth(50);
    } else {
      // Collapse the output section
      setLeftWidth(30);  // Reset to your desired default width when collapsed
      setEditorWidth(70);
      setOutputWidth(0);
    }
  };

  /* reset daat  */
  const resetEditor = () => {
    if (editorRef.current) {
      const emptyContent = `\n`.repeat(14); // Adjust the number to show required line numbers
      editorRef.current.setValue(emptyContent); // Set the editor content to emptyContent
      editorRef.current.setPosition({ lineNumber: 1, column: 1 }); // Set cursor at the first line and column
      editorRef.current.focus(); // Refocus the editor to ensure it's active
    }
  };


  // Draggable divider event handlers for left-right division
  const startDraggingLeft = (e) => {
    setIsDraggingLeft(true);
    e.preventDefault();
  };

  const stopDraggingLeft = () => {
    if (isDraggingLeft) {
      setIsDraggingLeft(false);
    }
  };

  const onDragLeft = (e) => {
    if (isDraggingLeft && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const newLeftWidth = (e.clientX / containerWidth) * 100;
      if (newLeftWidth > 10 && newLeftWidth < 90) { // Ensure the divider doesn't go too far to either side
        setLeftWidth(newLeftWidth);
        setEditorWidth(100 - newLeftWidth - outputWidth);
      }
    }
  };




  // Draggable divider event handlers for editor-output division
  const startDraggingEditorOutput = (e) => {
    setIsDraggingEditorOutput(true);
    e.preventDefault();
  };

  const stopDraggingEditorOutput = () => {
    if (isDraggingEditorOutput) {
      setIsDraggingEditorOutput(false);
    }
  };

  const onDragEditorOutput = (e) => {
    if (isDraggingEditorOutput && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const newEditorWidth = (e.clientX / containerWidth) * 100 - leftWidth;
      if (newEditorWidth > 10 && newEditorWidth < 90) {
        setEditorWidth(newEditorWidth);
        setOutputWidth(100 - leftWidth - newEditorWidth);
      }
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const closeOutput = () => {
    setShowOutput(false);
    setLeftWidth(30);
    setEditorWidth(70);
  };

  return (

    <div
      className={`new_labpage-app-container ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}
      ref={containerRef}
      onMouseMove={(e) => { onDragLeft(e); onDragEditorOutput(e); }}
      onMouseLeave={() => { stopDraggingLeft(); stopDraggingEditorOutput(); }}
      onMouseUp={() => { stopDraggingLeft(); stopDraggingEditorOutput(); }}
    >
      <div className="new_labpage-header-container">
        <div className="new_labpage-left-side-header">
          <h3 className="new_labpage_Technology_name">Python</h3>
        </div>
        <div className="new_labpage-right-side-header">
          <div className="new_labpage-right-header-buttons"></div>
          <div className="new_labpage-right-header-actions">
            <button className="new_labpage-exit-button" onClick={handleExitLabClick}>Exit</button>
            <img
              src={output_code_icon}
              alt="OutPut-Code-Icon"
              className={`new_labpage-output-icon ${showOutput ? 'active' : ''}`}
              onClick={toggleOutput}
            />
            <img src={Theme_icon} alt="OutPut-Code-Icon" className="new_labpage-theme-icon" onClick={toggleTheme} />
            <button className="new_labpage-submit-button">Submit</button>
          </div>
        </div>
      </div>
      <div className="new_labpage-app-content">
        {!showSolution ? (
          <div className="new_labpage-left-side" style={{ width: `${leftWidth}%` }}>
            <div className="new_labpage-header">
              <div className="new_labpage-tab-buttons">
                <button
                  onClick={() => handleTabClick('challenge')}
                  className={activeTab === 'challenge' ? 'active' : ''}
                >
                  Challenge
                </button>
                <button
                  onClick={() => handleTabClick('instructions')}
                  className={activeTab === 'instructions' ? 'active' : ''}
                >
                  Instructions
                </button>
                <button
                  onClick={() => handleTabClick('hints')}
                  className={activeTab === 'hints' ? 'active' : ''}
                >
                  Hints
                </button>
              </div>
              <div className="new_labpage-solution-pagination">
                <div className="new_labpage-icon-solution-container" onClick={handleSolutionClick}>
                  <img src={Solution_icon2} alt="Solution Icon" className="new_labpage-solution-icon" />
                  <p className="new_labpage-icon-solution">Solution</p>
                </div>
                <div className="new_labpage-pagination">
                  <img src={Backward_icon} alt="Previous" className="new_labpage-Backward-icon" />
                  <span>1/6</span>
                  <img src={Fordward_icon} alt="Next" className="new_labpage-fordward-icon" />
                </div>
              </div>
            </div>
            <div className="new_labpage-content-container">
              <div ref={challengeRef} className="new_labpage-content-section">
                <h2 className="new_labpage_challenge_title">Challenge</h2>
                <div className="new_labpage_challenge_pera" dangerouslySetInnerHTML={{ __html: content.challenge }} />
                <div className="new_labpage_challenge_pera">
                  {content.testCases && content.testCases.length > 0 && (
                    <div >
                      <h4 className="new_labpage_test_cases_title">Test Cases:</h4>
                      <ul>
                        {content.testCases.map((testCase, index) => (
                          <li key={index} >
                            <div dangerouslySetInnerHTML={{ __html: testCase.input }} />
                            <div dangerouslySetInnerHTML={{ __html: testCase.expected_result }} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div ref={instructionsRef} className="new_labpage-content-section">
                <div className="new_labpage-instructions-header">
                  <img src={instruction_icon2} alt="Instructions Icon" className="new_labpage-instruction-icon" />
                  <h2 className=" new_labpage_challenge_title">Instructions</h2>
                </div>
                <div className="new_labpage_challenge_pera" dangerouslySetInnerHTML={{ __html: content.instructions }} />
              </div>
              <div ref={hintsRef} className="new_labpage-content-section">
                <div className="new_labpage-hint-header">
                  <img src={Hint2_icon} alt="Hints Icon" className="new_labpage-hint-icon" />
                  <h2 className=" new_labpage_challenge_title" >Hints</h2>
                </div >
                <div className="new_labpage_challenge_pera" dangerouslySetInnerHTML={{ __html: content.hints }} />
              </div>
            </div>
          </div>
        ) : (
          <div className="new_labpage-left-side" style={{ width: `${leftWidth}%` }}>
            <div className="new_labpage-header">
              <div className="new_labpage-tab-back_buttons">
                <button onClick={handleCloseSolution}>Back</button>
              </div>
            </div>
            <div ref={solutionRef} className="new_labpage-content-section_solution">
              <h2 className=" new_labpage_challenge_title" >Solution</h2>
              <div className="new_labpage_challenge_pera" dangerouslySetInnerHTML={{ __html: content.solution }} />
            </div>
          </div>
        )}
        <div
          className="new_labpage-divider"
          onMouseDown={startDraggingLeft}
        >
          <div className="new_labpage-divider-icon">||</div>
        </div>
        <div className="new_labpage-editor-section" style={{ width: `${editorWidth}%` }}>

          <MonacoEditor
            height="91.4%"
            defaultLanguage="python"
            defaultValue={defaultContent}
            options={{
              lineNumbers: 'on',
              wordWrap: 'off',
              scrollBeyondLastLine: false,
              selectOnLineNumbers: true,
              lineNumbersMinChars: 6,
              fontSize: 14,  // Font size for the editor, consider increasing if needed for overall editor clarity
              lineHeight: 1.8,  // Line height for the editor
              minimap: {
                enabled: true,
                size: 'fit',  // 'fill' to make the minimap take up all available height
                scale: 5,  // Further increase the scale for a bigger and clearer minimap
                side: 'right',  // Place the minimap on the right
                showSlider: 'always',  // Ensure the minimap slider is always visible
                renderCharacters: true,  // Render actual characters for more detail
                maxColumn: 160  // Increase the width to show more data per line, enhancing clarity
              }
            }}
            theme={isDarkTheme ? 'vs-dark' : 'light'}
            onMount={(editor) => {
              editorRef.current = editor;
              editorRef.current.setPosition({ lineNumber: 1, column: 1 });
              editorRef.current.focus();
            }}
          />

          <div className="new_labpage-editor-footer">
            <button className="new_labpage-reset-button" onClick={resetEditor}>⟳</button>

            <div className="new_labpage-run-container">
              <button className="new_labpage-run-button" onClick={runCode} disabled={isLoading}>
                <img src={Play_icon} alt="Play Icon" className="new_labpage_run-code-icon" />
                {isLoading ? 'Running...' : 'Run code'}
              </button>
            </div>
          </div>
        </div>
        <div
          className="new_labpage-editor-output-divider"
          onMouseDown={startDraggingEditorOutput}
        >
          <div className="new_labpage-editor-output-divider-icon">||</div>
        </div>
        {showOutput && (
          <div
            className="new_labpage-output-section"
            style={{ width: `${outputWidth}%` }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="new_labpage-output-header">
              <span className="new_labpage-output-title">Output</span>
              <img
                src={output_maximize_icon}
                alt="Expand Icon"
                className="new_labpage-expand-icon"
                onClick={expandOutput}
              />
              {isHovered && (
                <img
                  src={close_icon}
                  alt="Close Icon"
                  className="new_labpage-close-icon"
                  onClick={closeOutput}
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                />
              )}
            </div>
            <div className={`new_labpage-output-console ${isError ? 'error' : ''}`} >
              {outputs.map((output, index) => <div key={index}>{output}</div>)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CodeEditor;