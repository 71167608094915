// import React from 'react';
// import '../assets/Css/SuccessMessage.css';
// import DW from '../assets/Images/Successfull_image.png';
// import Circle1 from '../assets/Images/Ellipse_Full.png';
// import Circle2 from '../assets/Images/Ellipse_Half.png';
// import Success_icon from '../assets/Images/Success_tick.png';



// const ConfirmationPage = () => {
//   return (
//     <div className="container">
//       <img src={DW} alt="dw" className="success-image" />
//       <div>
//         <div className="success-circles">
//           <img src={Circle1} alt="Circle 1" className="login-circle login-circle1" />
//           <img src={Circle2} alt="Circle 2" className="login-circle login-circle2" />
//         </div>
//       </div>
//       <div className="success-message">
//         <h1>Updated Successfully</h1>
//           <div className="image-text-container">
//             <img src={Success_icon} alt="Success" className="success-image" />
//             <p>Success! Your password has been changed.</p>
//           </div>
//         <button type="button">Sign in</button>
//       </div>
//     </div>
//   );
// };

// export default ConfirmationPage;






import React from 'react';
import '../assets/Css/SuccessMessage.css';
import DW from '../assets/Images/Successfull_image.png';
import Circle1 from '../assets/Images/Ellipse_Full.png';
import Circle2 from '../assets/Images/Ellipse_Half.png';
import Success_icon from '../assets/Images/Success_tick.png';
 
 
 
const ConfirmationPage = () => {
  return (
    <div className="SuccessMessage-main-container">
    <div className="SuccessMessage-container">
      <img src={DW} alt="dw" className="SuccessMessage-image" />
      <div>
        <div className="SuccessMessage-circles">
          <img src={Circle1} alt="Circle 1" className="SuccessMessage-circle SuccessMessage-circle1" />
          <img src={Circle2} alt="Circle 2" className="SuccessMessage-circle SuccessMessage-circle2" />
        </div>
      </div>
      <div className="SuccessMessage-message">
        <h2>Updated Successfully</h2>
          <div className="SuccessMessage-image-text-container">
            <img src={Success_icon} alt="Success" className="SuccessMessage-success-image" />
            <p>Success! Your password has been changed.</p>
          </div>
        <button type="button" className='SuccessMessage-button'>Sign in</button>
      </div>
    </div>
    </div>
  );
};
 
export default ConfirmationPage;

