import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';

function SkeletonLoader() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
      <Card style={{width:'100%', margin:'2px 15px'}}>
        <Skeleton sx={{ height: '68vh'  }} animation="wave" variant="rectangular" />
        <CardContent>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6  }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </CardContent>
      </Card>
    </div>
  );
}

export default SkeletonLoader;
