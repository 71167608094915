import React, {useState} from 'react';
import '../assets/Css/ForgotPassword.css';
import Circle1 from '../assets/Images/Ellipse_Full.png';
import Circle2 from '../assets/Images/Ellipse_Half.png';
import Forgotimg from '../assets/Images/Forgotimg.png'
import {useNavigate} from 'react-router-dom'
import { CircularProgress } from '@mui/material';
import axios from 'axios';

const ForgetPassword = () => {
    const [email, setEmail] = useState(''); 
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [userId,setUserId] = useState()
    localStorage.setItem('newUSerId',userId)
    const navigate = useNavigate()
  
    const handleForget = async (event) => {
      event.preventDefault();
      setLoading(true);
      setError('');
  
  
      const payload = {
        
        email,
        
      };
  
      try {
        const response = await axios.post('/api/password-reset/request/', payload);
        console.log('Signup success:', response.data);
        setUserId(response.data.
          user_id
          )
        navigate('/otpConfirm')
       
      } catch (err) {
        console.error('Signup error:', err);
        const { data } = err.response;
          if (data.error) {
            setError(data.error); 
          }
      }
  
      setLoading(false);
    };
  
    
  return (
    <div className="ForgotPassword-container">
      <div className="ForgotPassword-left">
        <img src={Forgotimg} alt='forgotimg' className='left-img' />
      </div>
      <div className="ForgotPassword-right">
        <div className="ForgotPassword-circles">
          <img src={Circle1} alt="Circle 1" className="ForgotPassword-circle  ForgotPassword-circle1" />
          <img src={Circle2} alt="Circle 2" className="ForgotPassword-circle  ForgotPassword-circle2" />
        </div>
        <div className="ForgotPassword-box">
          <h2>Forget your password</h2>
          <form  onSubmit={handleForget}>
            <div className="ForgotPassword-form-group">
              <label for='emailLabel' className='label_feild'>Enter your registered email address</label>
              <input className='ForgotPassword-form-control-forget' type="email" id='emailLabel' placeholder="Email I’d" required onChange={e => setEmail(e.target.value)} defaultValue={email} />
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <button className='ForgotPassword-button-email-forget' disabled={loading} >
             Continue {loading ? <CircularProgress size={15} color="inherit" style={{ marginLeft: "10px", marginTop: "3px" }} /> : ''}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
