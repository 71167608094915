import React from 'react'
import { useSelector } from "react-redux";
import Sidebar from '../Sidebar/Sidebar';

const Users = () => {
  const basicSidebarToggle = useSelector(
    (state) => state.basicSidebar.basicSideToggle
  );
  return (
    <div 
     style={{
      display: "flex",
      justifyContent: "flex-start",
      height: "90vh",
      width: basicSidebarToggle ? "100%" : "100%",
     
    }}
    >
      <Sidebar />
      <div style={{padding:'20px'}}>
      <h4>Users In Progress</h4>
      </div>
      
    </div>
  )
}

export default Users
